import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { default as config } from '../../../config/config';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { VehicleActions } from '../../../store/action';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router';
import FileUpload from './VehicleFileUpload'
import FullPageLoader from '../../common/FullPageLoader';
import ConfirmationModal from '../../common/ConfirmationModal';
import SaveVehicleForm from "./SaveVehicleForm";

class SaveVehicle extends Component {
  _isMounted = false

  constructor(props) {
    super(props);
    this.state = this.initialState();
  }

  initialState = () => {
    return {
      selectedOption: null,
     
      vehicleFile: '',
      vehicleId: this.props.match.params.id,
      submitted: false,
      loading: true,
     
      imagesList: [],
      redirecTo: null,
     
      files: [],
      removeAllImageFlag: false,
      deleteAllImagesLoading: false,
      showSendToInspectionConfirmationModal: false,
      openSelectBox:[]
    };


  }

  componentDidMount = async () => {
    this._isMounted = true
  }

  /**
   * VEHICLE IMAGE UPLOAD HANDLER
   */
  uploadVehicleImages = async (vehicleData = null ) => {
    let savedVehicleData = vehicleData
    let files = this.state.files
    
    let fileValid = true;

    for (let fileData of files) {

      let fileName = fileData.name

      //IMAGE FILES ALLOWED
      if (fileName && !(/\.(png|jpg|jpeg)$/.test(fileName.toLowerCase()))) {
        fileValid = false
      }

    }
    if (fileValid) {

      if (this._isMounted) this.setState({ loading: true });
      //PREPARE UPLOAD FILE ARRAY
      let postData = new FormData();

      let prevSavedImagesArray = []

      postData.append("dealer_id", savedVehicleData.dealer_id);
      for (let fileData of files) {
        if (fileData instanceof File) {
          postData.append("images", fileData);
        } else { //FOR OLD IMAGES, GET ONLY IMAGES FROM SAVED VEHICLE DATA WHICH ARE PRESENT IN FILE UPLOAD BOX
          if (this.state.vehicleId && this.state.saved_vehicle_data && this.state.saved_vehicle_data.usedCarImage) {
            let getSavedImageData = this.state.saved_vehicle_data.usedCarImage.filter(img => img.image_name === fileData.filename);
            if (getSavedImageData.length) {
              prevSavedImagesArray.push(getSavedImageData[0])
            }
          }
        }
      }

      postData.append('upload_type', config.constants.INVENTORY_IMAGE_FOLDER)
      postData.append('vehicle_id', savedVehicleData.car_id_hash)
      postData.append('stock_car_id', savedVehicleData.id || savedVehicleData.car_id)
      postData.append('make_id', savedVehicleData.make_id)
      postData.append('model_id', savedVehicleData.model_id)
      postData.append('version_id', savedVehicleData.version_id)
      postData.append('qc_status', savedVehicleData.qc_status)

      if (this.state.vehicleId && prevSavedImagesArray.length) {
        postData.append('prev_images', JSON.stringify(prevSavedImagesArray))
      }


      let headers = {
        'Content-Type': `multipart/form-data; boundary=${postData._boundary}`,
      }

      toast.success(this.props.t('pages.vehicle.edit.image_uploading_please_wait'));

      let searchResult = await this.props.actions.uploadVehicleImages(postData, headers);

      let imagesArray = this.state.imagesList;

      if (searchResult.status === 200) {
        if (searchResult.data) {
          toast.success(searchResult.message);
          
          let uploadedFilesArray = searchResult.data.map(file => { return { id: file.value.id, image_name: file.value.image_name, url: file.value.image_url } });
          //UPDATE IMAGE LIST IN STATE
          imagesArray = uploadedFilesArray

          if(vehicleData.car_id_hash){
            // this.props.history.push(`/edit-vehicle/${vehicleData.car_id_hash}`);

            if(!this.state.vehicleId){
              this.props.history.push( {
                pathname: `/edit-vehicle/${vehicleData.car_id_hash}`,
                state :{
                  imagesArray
                }
              });
            }
            

            
          } 

        } else {
          toast.error(searchResult.message);
        }
        if (this._isMounted) this.setState({ loading: false, imagesList: imagesArray, files: null });
        // this.fileInput.value = "";

      } else {
        if (this._isMounted) this.setState({ loading: false });
      }

    } else {
      toast.error(this.props.t('pages.vehicle.add.drop_image_files_title'));
      if (this._isMounted) this.setState({ loading: false });
    }
  }

  /**
  * REMOVE VEHICLE ALL IMAGE HANDLER 
  */
  removeAllImages = async () => {

    this.setState({  showBulkDeleteConfirmationModal: true })
  }

  /**
   * 
   * @param {Files array from Drag Drop Upload component} files 
   */
  handelFilesUpload = (files, newFileUpload = false) => {
    this.setState({ files, newFileUpload });
  }


  updateParentState = (data) => {
    this.setState({...this.state, ...data})
  }


  /**
     * HANDLE CONFIRMATION MODAL RESPONSE
     * @param {TRUE/FALSE} response 
     */
  confirmationImagesDelete = (response) => { 
    //IF SELECTED YES THEN SEND DATA TO PARENT TO TRIGGER BULK DELETE SERVICE
    if (response && response.response) {
      this.setState({removeAllImageFlag: true, deleteAllImagesLoading: true})

        let removeImageArray = {
          usedcar_id: this.state.saved_vehicle_data['id'],
          make_id: this.state.saved_vehicle_data['make_id'],
          model_id: this.state.saved_vehicle_data['model_id'],
          version_id: this.state.saved_vehicle_data['version_id'],
          dealer_id: this.state.saved_vehicle_data['dealer_id']
        }
    
        this.props.actions.removeVehicleImage(removeImageArray).then(resp => {
          if (resp.status === 200) {
            toast.success(resp.message);
    
            this.setState({ imagesList: null, removeAllImageFlag: false, deleteAllImagesLoading: false, files:null })
            //RESET HISTORY STATE 
            window.history.replaceState(null, '')
          }else{
            this.setState({ deleteAllImagesLoading: false})
          }
        }).catch(err=> this.setState({ deleteAllImagesLoading: false}));
      
    }
    this.setState({ showBulkDeleteConfirmationModal: false })

  }

  render() {
    const { submitted, files, newFileUpload, loading, vehicleId, redirecTo, saved_vehicle_data, removeAllImageFlag,showBulkDeleteConfirmationModal, deleteAllImagesLoading } = this.state;
    
    //CHECK IMAGES FROM STATE OR FOR FIRST TIME CHECK FROM HISTORY STATE 
    let imagesList = this.state.imagesList;
    imagesList = imagesList && imagesList.length ? imagesList : ( (this.props.location.state && this.props.location.state.imagesArray) || [] ) 

    let isAuctionCarExpired =  (saved_vehicle_data && saved_vehicle_data.isAuctionCarExpired ==1) ? saved_vehicle_data.isAuctionCarExpired:0;
    
    return (
      <>
        {
          redirecTo
            ?
            <Redirect to={{
              pathname: '/' + redirecTo,
              state: { from: 'save-vehicle' }
            }}
            />
            :
            ''
        }

        <div className="container-fluid-full">
          <FullPageLoader show={loading || submitted} />
          <div className="edit-vechile-heading">
            <div className="heading-txt">
              {
                vehicleId
                  ?
                  <>
                    <span>{this.props.t('pages.vehicle.edit.page_title')}</span>
                    <h1>{saved_vehicle_data ? ((saved_vehicle_data.make || '') + ' ' + (saved_vehicle_data.modelVersion || '')) : ''}</h1>
                  </>
                  :
                  <span>{this.props.t('pages.vehicle.add.page_title')}</span>
              }
            </div>

            {
              imagesList.length && (this.props.loginType !='seller') && (isAuctionCarExpired !=1)
                ?
                <div className="delete-icn">
                  {
                    deleteAllImagesLoading
                    ?
                      <button className="btn-line" disabled>
                        <i className="ic-delete-icn"></i>
                        <span>{this.props.t('pages.vehicle.edit.delete_vehicle_images_pease_wait')} </span>
                      </button>
                    :
                      <button className="btn-line" onClick={() => this.removeAllImages()}>
                        <i className="ic-delete-icn"></i>
                        <span>{this.props.t('pages.vehicle.edit.delete_vehicle_images')} </span>
                      </button>
                  }
                  
                </div>
                :
                ''
            }


          </div>
          <div className="edit-vehile-sec">

            <div className="edit-file-upload-bx">
              <FileUpload preUploadedImages={imagesList} componentData={{ removeAllImageFlag }} handelFilesUpload={this.handelFilesUpload} loginType={this.props.loginType} isAuctionCarExpired={isAuctionCarExpired} />
            </div>
            
            <SaveVehicleForm  {...this.props} updateParentState={this.updateParentState} componentData={{files, newFileUpload}} uploadVehicleImages={this.uploadVehicleImages} confirmationModalResponse={this.confirmationImagesDelete}/>

          </div>

        </div>


        {/* BULK DELETE MODAL */}
          {
            showBulkDeleteConfirmationModal
            ?
              <ConfirmationModal confirmationData={{ show: showBulkDeleteConfirmationModal, confirmationText: "Are you sure you want to delete all images?" }} confirmationModalResponse={this.confirmationImagesDelete} />
            :
              ''

          }

      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {  
  return {
    inventoryFilters: state.common.inventoryFilterList || [],
    mmvList: state.common.mmvList || {},
    authUser: state.auth.authUser || {},
    allVehicleList: state.vehicle.vehicleList || {},
    getSellerList: state.common.sellerList || {},
    cityStateList: state.common.cityStateList || {},
    allVehicleDetails: state.vehicle.allVehicleDetails || {},
    auctionVehicleFilterList: state.common.auctionVehicleFilterList || {},
    loginType: state.auth.authUser.login_type || ''
  };
}


const mapDispatchToProps = dispatch => {
  return {
    actions: {
      uploadVehicleImages: bindActionCreators(
        VehicleActions.uploadVehicleImages,
        dispatch
      ),
      removeVehicleImage: bindActionCreators(
        VehicleActions.removeVehicleImage,
        dispatch
      )
    }
  }
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(SaveVehicle));