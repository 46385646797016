import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AuctionActions, VehicleActions } from "../../../store/action";
import { withRouter } from "react-router-dom";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { toast } from 'react-toastify';
import ContentLoader from '../../common/ContentLoader';
import { default as config } from '../../../config/config';


class AddAuctionCar extends Component {
    _isMount = false
    constructor(props) {
        super(props);
        
    
        this.state = {
            inventoryList:[],
            formData: {auction_id: this.props.inventoryData.auctionId},
            defaultList:[],
            defaultData:{},
            selectedList:[],
            loading:false,
            inventoryFilterState:{page_number: 1},
            isSaving: false,
            isScrolling: false,
            firstTimeLoading: true
      };
    }


    componentDidMount = async () => {
        this._isMount = true;

        this.listInventory()
    }

    componentWillUnmount = () => {
        this._isMount = false
    }
    

    listInventory = async (params={page_number: 1}) => {
        let assingnedStockList = []
        let { defaultList, defaultData, inventoryData, inventoryList , firstTimeLoading, formData} = this.state;

        let inventoryParams = params;
        this.setState({loading: true})
        let allInventoryList = {};

        //GET SAVED INVENTORY IDs
        if(this.props.inventoryData && this.props.inventoryData.saved_auction_data && this.props.inventoryData.saved_auction_data.auctionInventory && this.props.inventoryData.saved_auction_data.auctionInventory.length){
            let notIncludedCarId = this.props.inventoryData.saved_auction_data.auctionInventory.map(car=> car.vid)
            inventoryParams['not_included_car_id'] = notIncludedCarId
        }   

        inventoryParams['showCarsNotAddedInAuction'] = true;
        inventoryParams['auction_qc_status'] = config.constants.vehicleApproved;
        inventoryParams['auction_id'] = formData.auction_id;
        
        allInventoryList = await this.props.actions.getVehicleList(inventoryParams, false);

        if(this._isMount) this.setState({loading: false, isScrolling: false})
            
        if(allInventoryList.status===200){
            assingnedStockList = this.sortSelectOption(allInventoryList['data'])

            //CREATE DEFAULT LIST
            if(!inventoryParams.keyword && +inventoryParams.page_number === 1){ 
                defaultList = assingnedStockList;
                defaultData = allInventoryList;
                firstTimeLoading = false
                // inventoryData = allInventoryList;
            }

            inventoryData = allInventoryList;

            if(this._isMount){
                this.setState({
                    inventoryList: (allInventoryList.pagination && allInventoryList.pagination.cur_page === 1) ?  assingnedStockList : [...inventoryList, ...assingnedStockList], 
                    defaultList,
                    defaultData,
                    inventoryData,
                    firstTimeLoading
                })
            }
            
        }

    }

    /**
     * PREPARE SELECT OPTIONS
     */
    sortSelectOption = (params) => {
        let options = []
        params.forEach((element, key) => {
            options.push({...element, value: element.id, label: element.make+ ' '+ element.modelVersion + (element.reg_no ? ' ('+element.reg_no +') ' : '') })
        });
        return options
    }
    
    /**
     * STORE SELECTED OPTIONS
     */
    handleChange = () => async e => {
        if(this._isMount) this.setState({selectedList: e});
    }

    /**
     * SAVE VEHICLE
     */
    saveCar = async() => {
        let { inventoryData } =this.props
        if(this.state.selectedList && this.state.selectedList.length){

            let selectedInventoryData = [];
            //PREPARE DATA 
            for(let inventory of this.state.selectedList){
                let saveCarParams = {
                    id: inventory.id, 
                    make_name: inventory.make, 
                    make_id: inventory.make_id, 
                    model_id: inventory.model_id, 
                    model_name: inventory.model,
                    version_id: inventory.version_id, 
                    version_name: inventory.carversion, 
                    reg_no: inventory.reg_no, 
                    base_price: inventory.car_price, 
                    expected_price: inventory.expected_price, 
                    reserved_price: inventory.reserved_price,
                    qc_status: inventory.auction_qc_status_value || 0
                  }

                //DEFAULT START/END DATE IS FROM AUCTION
                if(inventoryData.saved_auction_data && inventoryData.saved_auction_data.start_date_time){

                    saveCarParams['start_date_time'] = inventoryData.saved_auction_data.start_date_time
                    saveCarParams['end_date_time'] = inventoryData.saved_auction_data.end_date_time
                    
                }

                selectedInventoryData.push(saveCarParams)

            }
            
            let params = {
                ...this.state.formData,
                auction_inventory: selectedInventoryData
            }

            
            if(this._isMount) this.setState({isSaving: true})
            this.props.actions.saveAuctionCar(params).then(res=>{
                if(res.status===200){
                    toast.success(res.message);
                    this.setState({formData:{auction_id: this.state.formData.auction_id}, selectedList:[]})
                    //CLOSE MODAL
                    this.props.handleClose()

                    //update auction cars list
                    this.props.getAuctionCarsList()
                }
                if(this._isMount) this.setState({isSaving: false})

                
            }).catch(err=> {
                if(this._isMount) this.setState({isSaving: false})
            });
        }else{
            toast.error(this.props.t('pages.auction.manage.please_select_car'))
        }
        
    }

    /**
     * SEARCH INVENTORY
     */
    searchCar = (e, type) => {
        let {inventoryList, defaultList, inventoryFilterState, defaultData} = this.state;
        //IF SEARCH KEYWORD EXISTS THEN CALL INVENTORY API
        let searchValue = e.target.value
        searchValue = ((typeof searchValue !== 'undefined' && searchValue.trim() !== '') ? searchValue : undefined) 
        if(searchValue && searchValue.length>=1){
            this.listInventory({page_number:1, keyword: searchValue})
            this.setState({inventoryFilterState: {...inventoryFilterState, keyword: searchValue}})
        }
        else{ 
            if(this._isMount){
                this.setState({
                    inventoryList: defaultList.length ? defaultList : inventoryList, 
                    inventoryData: defaultData,
                    inventoryFilterState: {page_number: 1}
                })
            }
            
        }
    }

    /**
     * SCROLL EVENT ON LEFT SIDE INVENTORY LIST
     * @param {scroll event} event 
     */
    inventoryScroll = (event) => { 
        let { inventoryData , inventoryFilterState} = this.state;
        
        this.setState({isScrolling: true})
        if(inventoryData && inventoryData.pagination && inventoryData.pagination.cur_page){
            this.listInventory({...inventoryFilterState, page_number: inventoryData.pagination.cur_page+1})
        }
    }

    render() {        
        const { isScrolling, isSaving, inventoryList, loading, selectedList, firstTimeLoading } = this.state;

        //ALSO SHOW SELECTED VEHICLES IN OPTIONS
        for(let selected of selectedList){
            let checkInInventoryList = inventoryList.filter(inventory=> inventory.value === selected.value);
            if(!checkInInventoryList.length){
                inventoryList.push(selected);
            }
        }

        return (

            <>
                <div className="modal-header">
                    <h2>Add Vehicle</h2>
                </div>
                <div className="modal-body" >
                    <fieldset className=" multiselect-dropDown">
                        <div className="material">
                            <label className="option-txt">Select from Approved List</label>
                            {

                                firstTimeLoading
                                ?
                                    <ContentLoader show={loading} />
                                :
                                    <ReactMultiSelectCheckboxes
                                        isMulti
                                        placeholderButtonLabel={this.props.t(
                                            "pages.auction.manage.search_car"
                                        )}
                                        onKeyDown={(e)=> setTimeout( ()=>{ this.searchCar(e, 'searchCar') },1000) }
                                        onChange={this.handleChange(
                                            "car_ids"
                                        )}
                                        options={inventoryList}
                                        value={selectedList}
                                        getOptionLabel={({ label }) => label}
                                        getOptionValue={({ value }) => value}
                                        // noOptionsMessage={()=>{return loading ? 'loading...' : 'No data found!';}}
                                        onMenuScrollToBottom={this.inventoryScroll}
                                        blurInputOnSelect={false}
                                        isLoading={loading}
                                        isDisabled={loading}

                                    />
                            }

                        </div>
                        
                    </fieldset>

                </div>
                {
                    !firstTimeLoading
                    ?
                        <div className="yes-no-btn">

                            {
                                !isSaving
                                ?
                                    <button type="button" className="btn-primary" onClick={this.saveCar} >{this.props.t('pages.auction.inventory.add_new')}</button>
                                :
                                    <button type="button" className="btn-primary" disabled >{this.props.t('common.please_wait')}</button>
                            }

                        </div>
                    :
                        ''

                }
                
            </>
                
        );
    }
}

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            getVehicleList:bindActionCreators(
                VehicleActions.getVehicleList,
                dispatch
            ),
            saveAuctionCar: bindActionCreators(
                AuctionActions.saveAuctionCar,
                dispatch
            )
        }
    }
}

export default withTranslation('common') (withRouter(connect(mapStateToProps,mapDispatchToProps)(AddAuctionCar)));

