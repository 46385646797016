import React, { Component } from 'react';
import "floating-label-react/styles.css";
import ManageBuyers from "./ManageBuyer"
import AddVechileAuction from "./AddVechileAuction"

class AuctionBuyers extends Component {
    constructor(props) {
        super(props);
        console.log(props)

        this.state = {

        };
    }


    render() {

        return (
            <div class="auction-buyer-outer">
                <ManageBuyers />
                {/* <----- */}
                <AddVechileAuction />

            </div>




        );
    }
}




export default AuctionBuyers;