import React from 'react';


class Dropdown extends React.Component {
    constructor(props) {
        super(props);

        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.handleMouseEvent = this.handleMouseEvent.bind(this);
        this.handleBlurEvent = this.handleBlurEvent.bind(this);
        this.hasFocus = this.hasFocus.bind(this);

        this.state = {
            show: false,
            showLoanhistryModal: false,
        };
    }

    componentDidMount() {
        // handles mouse events like click and dblclick
        document.addEventListener('mouseup', this.handleMouseEvent);
        // handles tabbing out of
        this.dropdown.addEventListener('focusout', this.handleBlurEvent);
    }

    hasFocus(target) {
        // React ref callbacks pass `null` when a component unmounts, so guard against `this.dropdown` not existing
        if (!this.dropdown) {
            return false;
        }
        var dropdownHasFocus = false;
        var nodeIterator = document.createNodeIterator(this.dropdown, NodeFilter.SHOW_ELEMENT, null, false);
        var node;

        while (node = nodeIterator.nextNode()) {
            if (node === target) {
                dropdownHasFocus = true;
                break;
            }
        }

        return dropdownHasFocus;
    }

    handleBlurEvent(e) {
        var dropdownHasFocus = this.hasFocus(e.relatedTarget);

        if (!dropdownHasFocus) {
            this.setState({
                show: false
            });
        }
    }

    handleMouseEvent(e) {
        var dropdownHasFocus = this.hasFocus(e.target);

        if (!dropdownHasFocus) {
            this.setState({
                show: false
            });
        }
    }

    toggleDropdown() {
        this.setState({
            show: !this.state.show
        });
    }

    render() {
        return (
            <div className={`dropdown ${this.state.show ? 'show' : ''}`} ref={(dropdown) => this.dropdown = dropdown}>
                <button
                    className=""
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded={this.state.show}
                    onClick={this.toggleDropdown}
                    disabled
                    style={{cursor: "not-allowed"}}>
                    <i className="ic-notification"></i>
                    {/* <span className="notification-count">
                        4
                        </span> */}
                    
                </button>
                <div
                    className="dropdown-menu makecalldropdwon"
                    aria-labelledby="dropdownMenuButton">
                    <ul>
                        <li>
                            <a href="#" className="read-msg">
                                <div className="heading">
                                    <h3>Payment Requested</h3>
                                    <span className="time-date">
                                        2 hour ago
                                </span>
                                </div>
                                <span className="notification-msg">
                                    Payment request has been sent for approval.
                                </span>
                                <div className="notication-data">
                                    <span>Loan ID - 12345</span>
                                </div>

                            </a>
                        </li>
                        <li>
                            <a href="/CustomerDocDetails">
                                <div className="heading">
                                    <h3>Payment Received</h3>
                                    <span className="time-date">
                                        12/07/2021, 2:45 PM
                                </span>
                                </div>
                                <span className="notification-msg">
                                    Payment received from BFI
                                </span>
                                <div className="notication-data">
                                    <span>Loan ID - 12345,</span>  <span>Amount - 1000000,</span>  <span>TXn ID - ABCGIIDK</span>
                                </div>

                            </a>
                        </li>

                        <li>
                            <a href="#">
                                <div className="heading">
                                    <h3>Payment Made</h3>
                                    <span className="time-date">
                                        12/07/2021, 2:45 PM
                                </span>
                                </div>
                                <span className="notification-msg">
                                    Payment made to Gaurav Arora Motors
                                </span>
                                <div className="notication-data">
                                    <span>Loan ID - 12345,</span>  <span>Amount - 1000000,</span>  <span>TXn ID - ABCGIIDK</span>
                                </div>

                            </a>
                        </li>

                        <li>
                            <a href="#">
                                <div className="heading">
                                    <h3>Payment Requested</h3>
                                    <span className="time-date">
                                        2 hour ago
                                </span>
                                </div>
                                <span className="notification-msg">
                                    Payment request has been sent for approval.
                                </span>
                                <div className="notication-data">
                                    <span>Loan ID - 12345</span>
                                </div>

                            </a>
                        </li>

                        <li>
                            <a href="#">
                                <div className="heading">
                                    <h3>Payment Requested</h3>
                                    <span className="time-date">
                                        2 hour ago
                                </span>
                                </div>
                                <span className="notification-msg">
                                    Payment request has been sent for approval.
                                </span>
                                <div className="notication-data">
                                    <span>Loan ID - 12345</span>
                                </div>

                            </a>
                        </li>

                        <li>
                            <a href="#">
                                <div className="heading">
                                    <h3>Payment Requested</h3>
                                    <span className="time-date">
                                        2 hour ago
                                </span>
                                </div>
                                <span className="notification-msg">
                                    Payment request has been sent for approval.
                                </span>
                                <div className="notication-data">
                                    <span>Loan ID - 12345</span>
                                </div>

                            </a>
                        </li>
                    </ul>

                </div>
            </div>

        );
    }
}

const Notifaction = () => {
    return (
        <div>
            <Dropdown />
        </div>
    );
}

export default Notifaction;
