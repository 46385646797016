import React, { Component } from 'react';
import Select from 'react-select';
import { StartDateCalendra, EndDateCalendra } from './DatePicker'
import "floating-label-react/styles.css";
import AuctionBuyers from './AuctionBuyer'

const Business = [
    { value: 'Bank', label: 'Bank' },
    { value: 'Bank1', label: 'Bank1' },
    { value: 'Bank2', label: 'Bank2' },
    { value: 'Bank3', label: 'Bank3' },
];
const Region = [
    { value: 'North', label: 'North' },
    { value: 'South', label: 'South' },
    { value: 'East', label: 'East' },
    { value: 'west', label: 'west' },
];
const Province = [
    { value: 'Province', label: 'Province' },
    { value: 'Province1', label: 'Province1' },
    { value: 'Province2', label: 'Province2' },
    { value: 'Province3', label: 'Province3' },
];
const City = [
    { value: 'Delhi', label: 'Dehli' },
    { value: 'Jaipur', label: 'Jaipur' },
    { value: 'Gurgaon', label: 'Gurgaon' },

];
const AuctionTL = [
    { value: 'Ravinder Singh', label: 'Ravinder Singh' },
    { value: 'Anil Kumar', label: 'Anil Kumar' },
    { value: 'Abhay Jain', label: 'Abhay Jain' },

];





class BasicDetail extends Component {
    constructor(props) {
        super(props);
        console.log(props)

        this.state = {
            BusinessOption: null,
            RegionOption: null,
            ProvinceOption: null,
            CityOption: null,
            AuctionTLOption: null,
        };
    }

    handleChangeBusiness = (BusinessOption) => {
        this.setState({ BusinessOption }
        );
    };
    handleChangeRegion = (RegionOption) => {
        this.setState({ RegionOption }
        );
    };
    handleChangeProvince = (ProvinceOption) => {
        this.setState({ ProvinceOption }
        );
    };
    handleChangeCity = (CityOption) => {
        this.setState({ CityOption }
        );
    };
    handleChangeAuctionTL = (AuctionTLOption) => {
        this.setState({ AuctionTLOption }
        );
    };

    render() {
        const { BusinessOption } = this.state;
        const { RegionOption } = this.state;
        const { ProvinceOption } = this.state;
        const { CityOption } = this.state;
        const { AuctionTLOption } = this.state;
        return (
            <div className="add-auction-form">
                <div class="row">
                    <div class="col-md-12">
                        <h3>Basic Details</h3>
                    </div>
                    <div class="col-md-4">
                        <fieldset class="">
                            <div class="material">
                                <input type="text" className="form-input" placeholder=" " />
                                <label data-label="Auction Name" class="form-label"></label>
                            </div>
                        </fieldset>
                    </div>

                    <div class="col-md-4">
                        <fieldset class=" select-drop-down">
                            <div class="material">
                                <Select
                                    value={BusinessOption}
                                    onChange={this.handleChangeBusiness}
                                    options={Business}
                                    placeholder=""
                                />
                                <label data-label="Business Type" class="form-label"></label>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-md-4">
                        <fieldset class=" select-drop-down">
                            <div class="material">
                                <Select
                                    value={RegionOption}
                                    onChange={this.handleChangeRegion}
                                    options={Region}
                                    placeholder=""
                                />
                                <label data-label="Region" class="form-label"></label>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-md-4">
                        <fieldset class=" select-drop-down">
                            <div class="material">
                                <Select
                                    value={ProvinceOption}
                                    onChange={this.handleChangeProvince}
                                    options={Province}
                                    placeholder=""
                                />
                                <label data-label="Province" class="form-label"></label>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-md-4">
                        <fieldset class=" select-drop-down">
                            <div class="material">
                                <Select
                                    value={CityOption}
                                    onChange={this.handleChangeCity}
                                    options={City}
                                    placeholder=""
                                />
                                <label data-label="City" class="form-label"></label>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-md-4">
                        <fieldset class=" select-drop-down">
                            <div class="material">
                                <Select
                                    value={AuctionTLOption}
                                    onChange={this.handleChangeAuctionTL}
                                    options={AuctionTL}
                                    placeholder=""
                                />
                                <label data-label="Auction TL" class="form-label"></label>
                            </div>
                        </fieldset>
                    </div>
                    <div className="col-md-4">
                        <fieldset class="create-date">
                            <div class="material">
                                <StartDateCalendra />
                            </div>
                        </fieldset>
                    </div>
                    <div className="col-md-4">
                        <fieldset class="create-date">
                            <div class="material">
                                <EndDateCalendra />
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>




        );
    }
}




export default BasicDetail;