import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import DatePicker from "react-datepicker"; 
import InputField from '../../elements/InputField';
import Moment from 'moment';
import { useDispatch } from 'react-redux'
import { AuctionActions } from '../../../store/action';
import CurrencyInputField from '../../elements/CurrencyInputField'; 
function PostAuctionStatusFields(props) {

    const [formFields, setFormFields] = useState({'post_auction_status': props.formFields.post_auction_status, 'follow_up_date':  props.formFields.follow_up_date, 'purchased_date':  props.formFields.purchased_date, 'purchased_amount':  props.formFields.purchased_amount, 'purchased_buyer_id':  props.formFields.purchased_buyer_id, 'post_auction_status_text': props.formFields.post_auction_status_text});

    const [vehicleData, setVehicleData] = useState(props.saved_vehicle_data);
    // const [postAuctionFields, setPostAuctionFields] = useState({});
    let [selectedPostAuction, setSlectedPostAuction] = useState([]);
    const [errors, setErros] = useState(props.errors);
    const [postAuctionBuyers, setPostAuctionBuyers] = useState([]);
    const dispatch = useDispatch();
    let [openSelectBox, setOpenSelectBox] = useState([]);

    useEffect(() => {

        //GET ALL BUYERS OF THIS AUCTION CAR
        dispatch(AuctionActions.getAuctionCarBuyers({car_id: vehicleData.id, type: 'bid'})).then(buyers => {
            if (buyers.status == 200) {
                
                setPostAuctionBuyers(buyers.data)
            }
        })
    },[]);

    const  handleChange = async (key, event) => {

        if(key === 'post_auction_status'){
            // selectedPostAuction.push(event.value);
            if(!event){
                // setFormFields(prevStatus=> ({ 'post_auction_status': null, 'follow_up_date':  null, 'purchased_date':  null, 'purchased_amount':  null}) )
                updateFormFields({ 'post_auction_status': null, 'follow_up_date':  null, 'purchased_date':  null, 'purchased_amount':  null, 'post_auction_status_text': null })

            }else{
                // setFormFields(prevStatus=> ({ 'post_auction_status': (event && event.value) || null}) )
                updateFormFields({ 'post_auction_status': (event && event.value) || null, 'post_auction_status_text': (event && event.label) || null})
                
            }

        }
        else if(key === 'follow_up_date' || key === 'purchased_date'){
            setFormFields(prevStatus=> ({...formFields, [key]: (event && new Date(event).toISOString()) || null}) )
            updateFormFields({ [key]: (event && new Date(event).toISOString()) || null })


        }else if(key === 'purchased_amount'){
            if (!isNaN(event.target.value)) {
                updateFormFields({ [key]: (event && event.target.value) || null })

            }      
        }else if(key === 'purchased_buyer_id'){
            if ((event.value)) {
                updateFormFields({ [key]: (event && event.value) || null })

            }      
        }
        
    }


    const updateFormFields = (formData) => {
        setFormFields(prevState=> ({ ...prevState, ...formData }) )
        props.updatePostAuctionFields(formData)

    }

    /**
     * 
     * ON SELECT CLICK HANDLER 
     */
     const onSelectOpen  = (el) => {
        // let {openSelectBox} = this.state;
        let selectedDiv = el+'_div';

        // this[selectedDiv].current.classList.add('active-label')
        document.getElementById(selectedDiv).classList.add('active-label')

        //ADD TO OPEN SELECTBOX ARRAY

        openSelectBox= openSelectBox.filter(select=> select!==el)
        openSelectBox.push(el);
        setOpenSelectBox(openSelectBox)

    }

    /**
     * ON SELECT MENU HIDE HANDLER 
     */
    const onSelectClose = (el) => {
        // let {openSelectBox, formFields} = this.state;
        let selectedDiv = el+'_div';

        //REMOVE IF EMPTY
        if(!formFields[el]){
            // this[selectedDiv].current.classList.remove('active-label')
            document.getElementById(selectedDiv).classList.remove('active-label')

            openSelectBox= openSelectBox.filter(select=> select!==el)
            setOpenSelectBox(openSelectBox)


        }
    }
    
    let postAuctionStatus = props.componentData.postAuctionStatus;

    let { winner_buyer_data } = vehicleData || {};

    return (

            formFields
            ?
                <>
                    <div className="Summary-heading">
                        <h2>{props.t('pages.vehicle.edit.post_auction_updates')}</h2>
                    </div>

                    <div className="edit-form row" >

                        <fieldset className="form-select-bx col-md-4">
                            <div className={"material animation-effect "+ (formFields['post_auction_status'] ?  ' active-label' : '')} /*ref={this.post_auction_status}*/>

                            <Select
                                id="post_auction_status"
                                name="post_auction_status"
                                onChange={handleChange.bind(this, 'post_auction_status')}
                                options={postAuctionStatus || []}
                                value={(typeof formFields['post_auction_status'] !== 'undefined') && postAuctionStatus && postAuctionStatus.filter((type) => Number(type.value) === Number(formFields.post_auction_status))}
                                isClearable={true}
                                placeholder=""
                                classNamePrefix='auction-dropdown'

                            />
                            <label data-label={props.t('pages.vehicle.edit.post_auction_status')} className="form-label"></label>

                            </div>
                        </fieldset>

                        
                            {
                                formFields['post_auction_status']
                                ?
                                <fieldset className="form-select-bx col-md-4">
                                    <div className={"material animation-effect " + (formFields['follow_up_date'] ? 'active-label' : '')} /*ref={this.follow_up_date}*/ >
                                        <div className='datepicker'>
                                            <DatePicker
                                            name="follow_up_date"
                                            selected={formFields.follow_up_date ? new Date(formFields.follow_up_date) : null}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            isClearable
                                            dropdownMode="select"
                                            placeholderText={props.t('pages.vehicle.edit.followup_date')}
                                            autoComplete="off"
                                            onChange={handleChange.bind(this, 'follow_up_date')}
                                            dateFormat={"dd-MM-yyyy"}
                                            
                                            />
                                            <label data-label={props.t('pages.vehicle.edit.followup_date')} className="form-label"></label>

                                        </div>
                                        
                                    </div>
                                </fieldset> 
                                :
                                ""
                            } 

                            {
                                (formFields['post_auction_status'] && +formFields['post_auction_status'] === 5)
                                ? 
                                <>
                                    <fieldset className="form-select-bx col-md-4">
                                        <div className={"material animation-effect " + (formFields['purchased_date'] ? 'active-label' : '')} /*ref={this.purchased_date}*/ >
                                            <div className='datepicker'>
                                                <DatePicker
                                                name="purchased_date"
                                                selected={formFields.purchased_date ? new Date(formFields.purchased_date) : null}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                isClearable
                                                dropdownMode="select"
                                                placeholderText={props.t('pages.vehicle.edit.purchased_date')}
                                                autoComplete="off"
                                                onChange={handleChange.bind(this, 'purchased_date')}
                                                dateFormat={"dd-MM-yyyy"}
                                                
                                                />
                                                <label data-label={props.t('pages.vehicle.edit.purchased_date')} className="form-label"></label>

                                            </div>
                                            {
                                                props.errors && props.errors['purchased_date']
                                                ?
                                                <span className="error-txt">{props.errors['purchased_date']}</span>
                                                :
                                                ''
                                            }
                                        </div>
                                    </fieldset> 

                                    <fieldset className="form-select-bx col-md-4">
                                        <div className={"material animation-effect "}>

                                        <CurrencyInputField
                                            inputProps={{
                                            id: "purchased_amount",
                                            type: "text",
                                            placeholder: " ",
                                            value: formFields['purchased_amount'] || '',
                                            className: 'form-input',
                                            // disabled: true

                                            }}
                                            onChange={handleChange.bind(this, 'purchased_amount')}

                                        />
                                        <label data-label={props.t('pages.vehicle.edit.purchased_amount')} className="form-label"></label>

                                        </div>
                                    </fieldset>
                                
                                    <fieldset className="datepicer col-md-4">
                                        <div className={"material animation-effect "  + (formFields['purchased_buyer_id'] ? 'active-label' : '')} id={"purchased_buyer_id_div"}  onClick={()=>onSelectOpen('purchased_buyer_id')}>
                                            <Select
                                                id="purchased_buyer_id"
                                                name="purchased_buyer_id"
                                                onChange={handleChange.bind(this, 'purchased_buyer_id')}
                                                options={postAuctionBuyers || []}
                                                value={(typeof formFields['purchased_buyer_id'] !== 'undefined') && postAuctionBuyers && postAuctionBuyers.filter((buyer) => Number(buyer.value) === Number(formFields.purchased_buyer_id))}
                                                // isClearable={true}
                                                placeholder=""
                                                classNamePrefix='auction-dropdown'
                                                onMenuClose={()=>onSelectClose('purchased_buyer_id')}


                                            />
                                            <label data-label={props.t('pages.vehicle.edit.purchased_buyer_id')} className="form-label"></label>

                                            {
                                                props.errors && props.errors['purchased_buyer_id']
                                                ?
                                                <span className="error-txt">{props.errors['purchased_buyer_id']}</span>
                                                :
                                                ''
                                            }
                                        </div>
                                    </fieldset>
                                </>
                                :
                                ""
                            }
                        
                    </div>

                    

                    {
                        winner_buyer_data && Object.keys(winner_buyer_data).length && winner_buyer_data['winning_buyer'] && (!formFields['post_auction_status'] || (formFields['post_auction_status'] && +formFields['post_auction_status'] !== 8))
                        ?

                            <>
                                <div className="Summary-heading">
                                    <h2>{props.t('pages.vehicle.edit.winning_buyer_detail')}</h2>
                                </div>
                                <div className="edit-form row" >

                                    <fieldset className="form-select-bx col-md-4">
                                        <div className={"material animation-effect "}>

                                        <InputField
                                            inputProps={{
                                            id: "dealer_id",
                                            type: "text",
                                            placeholder: " ",
                                            value: winner_buyer_data['winning_buyer'] || '',
                                            className: 'form-input',
                                            disabled: true

                                            }}
                                        />
                                        <label data-label={props.t('pages.vehicle.edit.winner_dealer_id')} className="form-label"></label>

                                        </div>
                                    </fieldset>


                                    <fieldset className="form-select-bx col-md-4">
                                        <div className={"material animation-effect "} >
                                            <div className='datepicker'>
                                                <InputField
                                                        inputProps={{
                                                        id: "dealer_name",
                                                        type: "text",
                                                        placeholder: " ",
                                                        value: winner_buyer_data['organization'] || '',
                                                        className: 'form-input',
                                                        disabled: true

                                                        }}
                                                    />
                                                    <label data-label={props.t('pages.vehicle.edit.winner_dealer_organization')} className="form-label"></label>
                                            </div>
                                            
                                        </div>
                                    </fieldset> 

                                    <fieldset className="form-select-bx col-md-4">
                                        <div className={"material animation-effect " }>
                                        <InputField
                                            inputProps={{
                                            id: "dealer_mobile",
                                            type: "text",
                                            placeholder: " ",
                                            value: winner_buyer_data['dealership_contact'] || '',
                                            className: 'form-input',
                                            disabled: true

                                            }}
                                        />
                                        <label data-label={props.t('pages.vehicle.edit.winner_dealer_mobile')} className="form-label"></label>
                                        
                                        </div>
                                    </fieldset> 
                                
                                    <fieldset className="datepicer col-md-4">
                                        <div className={"material"}>
                                        <CurrencyInputField
                                            inputProps={{
                                            id: "winning_bid_amount",
                                            type: "text",
                                            name: "winning_bid_amount",
                                            placeholder: " ",
                                            value: winner_buyer_data['bid_winning_amount'] || '',
                                            className: 'form-input',
                                            disabled: true

                                            }}
                                        />
                                        <label data-label={props.t('pages.vehicle.edit.winner_dealer_bid_amount')} className="form-label"></label>
                                        </div>
                                    </fieldset>

                                    <fieldset className="datepicer col-md-4">
                                        <div className={"material"}>
                                        <InputField
                                            inputProps={{
                                            id: "bid_date_time",
                                            type: "text",
                                            placeholder: " ",
                                            value: winner_buyer_data['bid_created_date'] ? Moment(winner_buyer_data['bid_created_date']).format('DD-MM-yyyy HH:mm') : '',
                                            className: 'form-input',
                                            disabled: true

                                            }}
                                        />
                                        <label data-label={props.t('pages.vehicle.edit.winner_dealer_bid_date_time')} className="form-label"></label>
                                        </div>
                                    </fieldset>

                                    <fieldset className="datepicer col-md-4">
                                        <div className={"material"}>
                                        <InputField
                                            inputProps={{
                                            id: "aucton_name",
                                            type: "text",
                                            placeholder: " ",
                                            value: winner_buyer_data['auction_name'] || '',
                                            className: 'form-input',
                                            disabled: true

                                            }}
                                        />
                                        <label data-label={props.t('pages.vehicle.edit.aucton_name')} className="form-label"></label>
                                        </div>
                                    </fieldset>

                                </div>
                            </>
                        :
                            ""

                    }
                    


                </>
            :
                ""
            
        
    );
}

export default withTranslation('common')(PostAuctionStatusFields);
