import TopActionBtn from '../components/TopActionBar'

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { default as config } from '../../../config/config';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { VehicleActions } from '../../../store/action';
import FilterTable from '../../helpers/filter-table';
import FilterBox from '../../helpers/filter-box';
import DateFormat from 'dateformat';
import { NavLink } from "react-router-dom";
import Pagination from '../../elements/Pagination';
import * as HELPER from '../../../config/helper';
import { toast } from 'react-toastify';
import ImageUploadForInventory from '../components/ImageUploadForInventory';
import Modal from '../../elements/PopupModal';
import FullPageLoader from '../../common/FullPageLoader';

let createHistory = require("history").createBrowserHistory

class Vehicle extends Component {
  _isMounted = false

  constructor(props) {
    super(props);
    this.state = this.initialState();
  }
  initialState = () => {
    let loginUserRole = this.props.authUser && this.props.authUser.authUser && this.props.authUser.authUser.role || '';

    return {
      loading: false,
      resultList: [],
      pagination: {},
      totalLengthWithoutPagination: 0,
      runPaginationFunc: false,
      formAction: 'auctionList',
      tableHeaders: [
        { key: 'selectAll', value: 'pages.vehicle.list.selectAll' },
        { key: 'vehicleName', value: 'pages.vehicle.list.vehicle_name' },
        { key: 'vid', value: 'pages.vehicle.list.vid' },
        { key: 'reg_no', value: 'pages.vehicle.list.reg_no' },
        { key: 'seller_name', value: 'pages.vehicle.list.seller_name' },
        { key: 'location', value: 'pages.vehicle.list.location' },
        { key: 'qc_status', value: 'pages.vehicle.list.qc_status' },
        { key: 'post_auction_status', value: 'pages.vehicle.list.post_auction_status' },
        { key: 'auction_name', value: 'pages.vehicle.list.auction_name' },
        { key: 'auction_status', value: 'pages.vehicle.list.auction_status' },
        { key: 'createdAt', value: 'common.created_at', sort: 'asc' },
        { key: 'createdBy', value: 'common.created_by' },
        { key: 'actions', value: 'common.actions' }
      ],
      filterFieldsData: [
        { name: 'keyword', label: '', type: 'input', value: '', placeholder: 'pages.vehicle.list.search_by_vid_reg_no', createLabelRef: false, isVisible: true },
        { name: 'dealer_ids', label: '', type: 'multiselect', option: [], value: '', placeholder: 'pages.vehicle.list.seller', createLabelRef: true, isVisible: (loginUserRole !== "dealercentral") ? true : false},
        { name: 'car_make', label: '', type: 'multiselect', showCheckBox: true, option: [], value: '', placeholder: 'pages.vehicle.list.make', createLabelRef: true, isVisible: true },
        { name: 'car_model', label: '', type: 'multiselect', showCheckBox: true, option: [], value: '', placeholder: 'pages.vehicle.list.model', createLabelRef: true, isVisible: true },
        { name: 'created_from', label: '', type: 'date', placeholder: 'pages.vehicle.list.created_from', value: '', createLabelRef: true, isVisible: true, maxDate: new Date() },
        { name: 'created_to', label: '', type: 'date', placeholder: 'pages.vehicle.list.created_to', value: '', createLabelRef: true, isVisible: true, maxDate: new Date() },
        { name: 'registration_on', label: '', placeholder: 'pages.vehicle.list.registered_on', value: '', type: 'date', createLabelRef: true, isVisible: true, dateFormat: "MMMM yyyy" },
        { name: 'post_auction_status', label: '', placeholder: 'pages.vehicle.list.post_auction_status', value: '', option: [], type: 'select', createLabelRef: true, isVisible: true, className: "post-action-opt" },
        { name: 'auction_qc_status', label: '', placeholder: 'pages.vehicle.list.qc_status', value: '', option: [], type: 'select', createLabelRef: true, isVisible: true },
        { name: 'created_by', label: '', placeholder: 'pages.vehicle.list.created_by', value: '', type: 'select', option: [], createLabelRef: true, isVisible: true },
        { name: 'auction_status', label: '', placeholder: 'pages.vehicle.list.auction_status', value: '', type: 'select', option: config.constants.AUCTION_STATUS, createLabelRef: true, isVisible: true },
        { name: 'auction_id_hash', label: '', placeholder: 'pages.vehicle.list.auction_name', option: [], type: 'select', createLabelRef: true, isVisible: true }
      ],
      filterData: {},
      defaultFilters: {
        // category_id: 1, //DEFAULT VALUE
        // car_status: [1] //DEFAULT VALUE
      },
      tableHtmlData: [],
      checkedAll: false,
      resetFilterBoxPagination: false,
      page: 1,
      isFilterApply: false,
      zipImageUpload: false,
      inventoryData: {},
      multiSelectedVehicles: []
    };
  }

  componentDidMount = async () => {
    this._isMounted = true;
    this.setState({loading: true})
  }

  componentWillUnmount = () => {
    this._isMounted = false

    if (this.state.isFilterApply) {
      this.props.actions.resetVehicleListFromState()
    }
  }

  getVehicleList = async (data = {}, type = '') => {
    const history = createHistory();

    if (this._isMounted) await this.setState({ loading: true });

    //GET FROM STATE IF EXISTS AND PREV ROUTE IS NOT SAVE VEHICLE
    let searchResult = {}
    if (type === 'firstCall' && Object.keys(this.props.allVehicleList).length && (!this.props.history.location.state || (this.props.history.location.state && this.props.history.location.state.from !== 'save-vehicle'))) {
      searchResult = this.props.allVehicleList
    } else {
      searchResult = await this.props.actions.getVehicleList(data)
    }

    //RESET HISTORY STATE
    if (history.location.state && history.location.state.from) {
      let state = { ...history.location.state };
      delete state.from;
      history.replace({ ...history.location, state });
    }

    //UPDATE STATE
    if (this._isMounted) {

      let finalResult = (searchResult && searchResult.data ? searchResult.data : []);
      await this.setState({
        loading: false,
        resultList: finalResult,
        pagination: (searchResult && searchResult.pagination) ? searchResult.pagination : {},
        totalLengthWithoutPagination: ((searchResult && searchResult['pagination'] && searchResult['pagination']['total']) || 0),
        runPaginationFunc: true,
        vehicleListPagination: searchResult && searchResult.pagination ? searchResult.pagination : {}
      });

    }
    this.generateTableData();
  }


  getFilterList = async (searchParams) => {
    let postData = { ...this.state.defaultFilters, ...this.state.filterData, ...searchParams }
    postData['page_number'] = searchParams['page_number'];

    delete postData['action']
    this.getVehicleList(postData);
  }

  //HANDLE PAGINATE DATA FLAG RECIEVED FROM FilterTable SEND IT TO FilterBox COMPONENT
  callbackFunction = async (childData) => {
    if (this._isMounted) await this.setState({ runPaginationFunc: childData['runPaginationFunc'] });
  }

  handleRequestData = async (data, callFrom = '') => {
    let page = data.page_number;

    if (data['action'] && data['action'] === 'resetAll' && this._isMounted) {
      await this.setState({ filterData: this.state.defaultFilters, sortedArray: [], formAction: 'resetAll', loading: true, resultList: [], page: page, pagination: {}, totalLengthWithoutPagination: 0, runPaginationFunc: false });
      this.generateTableData();
      this.props.history.push("/vehicle-list")


    }
    if (this._isMounted) {

      await this.setState({
        formAction: '',
        loading: true,
        // leadList: [],
        page: page,
        pagination: {},
        // totalLengthWithoutPagination:  0, 
        runPaginationFunc: false,
        filterData: data,//{...this.state.filterData, ...data},
        isFilterApply: (callFrom === 'filterBox') ? true : false
      });
      this.getFilterList(data);
    }

  }

  /**
   * SINGLE CHECKBOX CHECK/UNCHECK HANDLING
   */
  handleSingleCheckboxChange = async (vehicleData) => {
    let { checkedAll, resultList, multiSelectedVehicles } = this.state;
    //ADD REMOVE INVENTORY ID FROM ARRAY ON CHECK/UNCHECK
    if (multiSelectedVehicles.includes(vehicleData.id)) {
      multiSelectedVehicles = multiSelectedVehicles.filter(ids => ids != vehicleData.id)
    } else {
      multiSelectedVehicles.push(vehicleData.id)
    }

    //IF SELECTALL CHECKED AND ANY OF  RECORD IN PAGE IS NOT SELECTED THEN RESET SELECTALL
    if (checkedAll && resultList.length !== multiSelectedVehicles.length) {
      checkedAll = false
    } else if (!checkedAll && resultList.length === multiSelectedVehicles.length) {
      checkedAll = true
    }

    await this.setState({ multiSelectedVehicles, checkedAll });

    this.generateTableData();
  }

  /**
   * HANDLE SELECT ALL
   */

  handledCheckedAll = async (checkedAll) => {
    if (this._isMounted) {
      let { multiSelectedVehicles, resultList } = this.state
      //CREATE INVENTORY ID ARRAY ON SELECT ALL
      if (checkedAll) {
        multiSelectedVehicles = resultList.map(vehicle => vehicle.id)
      } else {
        multiSelectedVehicles = []
      }

      await this.setState({ checkedAll, multiSelectedVehicles })
      this.generateTableData()
    }

  }
  showModalZipUpload = param => e => {
    this.setState({ zipImageUpload: true, inventoryData: param });
    document.body.classList.add("overflow-hidden");
  }
  hideModalZipUpload = () => {
    this.setState({ zipImageUpload: false, inventoryData: '' });
    document.body.classList.remove("overflow-hidden");
  }

  closeModalInventoryPopup = () => {
    this.hideModalZipUpload();
  }

  generateTableData = async () => {
    let html = []
    const { resultList, tableHeaders, checkedAll, multiSelectedVehicles,loading } = this.state;
    const { dateFormatDDMMMYYYYHIS } = config.constants;
    let postStatus =  this.props.auctionVehicleFilterList.POST_AUCTION_STATUS
    
    for (let [key, list] of resultList.entries()) {

      let setCheckBoxSelected = (multiSelectedVehicles.includes(list.id) || checkedAll) ? true : false
      let cityName = (list.city_name)? list.city_name: list.customer_city_name;
      let post_status = null;
      if(list.post_auction_status){
        post_status = postStatus.filter((item)=>item.value ==list.post_auction_status);
        post_status = (post_status.length >0)? post_status[0].label:'';
      } 
      html.push(
        <tr key={key}>
          <td>
            <li className="custom-control custom-checkbox">
              <input id="all" type="checkbox" className="custom-control-input" onChange={() => this.handleSingleCheckboxChange(list)} checked={setCheckBoxSelected} />
              <label htmlFor="all" className="custom-control-label"></label>
            </li>
          </td>
          <td title={list.make + ' ' + list.modelVersion}> {
            list.make + ' ' + list.modelVersion
          }</td>
          <td title={list.id}>{list.id}</td>
          <td>{list.reg_no}</td>
          <td title={list.seller_organization}><span className="name-txt">{list.seller_organization}</span></td> 
          <td title={cityName}>{cityName}</td> 
          <td title={list.auction_qc_status}>{list.auction_qc_status} </td>
          <td title={post_status}>{post_status} </td>
          <td title={list.auction_name}>{list.auction_name || ''} </td>
          <td title={list.auction_type || ''}>{list.auction_type || ''} </td>

          <td title={DateFormat(list.created_date, dateFormatDDMMMYYYYHIS)}>{
            DateFormat(list.created_date, dateFormatDDMMMYYYYHIS)
          }
          </td>
          <td>{list.created_by_name || ''}</td>
          <td>
            <div className="edit-zip-btn">
              {
                (HELPER.isUserHasAccessPage('edit-vehicle') && (this.props.loginType !='seller'))
                  ?
                  <>
                    <NavLink className="btn-view" to={"/edit-vehicle/" + list.car_id_hash} title={"Edit"}> Edit </NavLink> <button className="btn-view m-xs-l" onClick={this.showModalZipUpload(list)}>
                      <i className="ic-upload"></i> ZIP</button>
                  </>
                  :
                  <>
                    <NavLink className="btn-view" target="_blank" to={"/edit-vehicle/" + list.car_id_hash} title={"View"}> View </NavLink>  
                  </>
              }
            </div>
          </td>


        </tr>
      )
    }

    if (!resultList.length) {

      html.push(<tr key="1"><td colSpan={Object.keys(tableHeaders).length} className="text-center" style={{textAlign:'center'}}>{!loading ? this.props.t('common.no_records_found'):null}</td></tr>)
    }


    if (this._isMounted) this.setState({ tableHtmlData: html })
    return html;

  }

  exportXls = async () => {
    let postData = { ...this.state.defaultFilters, ...this.state.filterData };

    if (this._isMounted) await this.setState({ loading: true });

    this.props.actions.exportXls(postData).then(res => {
      if (this._isMounted) this.setState({ loading: false });

      if (res && res.data) {
        const link = document.createElement('a');
        link.href = `${res.data}`;
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        toast.success(res.message);
      }


    });
  }

  /**
  * DELETE BULK INVENTORY
  * @returns SUCCESS/FAILURE STATUS 
  */
  bulkInventoryDelete = async () => {
    let { multiSelectedVehicles } = this.state;
    this.props.actions.bulkInventoryDelete({ car_ids: multiSelectedVehicles }).then(response => {
      toast.success(response.message);
      //REFRESH INVENTORY
      this.getVehicleList({ page_number: 1 })

    }).catch(err => console.log(err))
  }


  /**
  * BULK INVENTORY SEND TO INSPECTION
  */
   bulkInventorySendToInspection = async () => {
     
    let { multiSelectedVehicles } = this.state;
    this.setState({ loading: true })
    this.props.actions.bulkInventorySendToInspection({ car_ids: multiSelectedVehicles }).then(async response => {

      toast.success(response.message);

      //RESET TABLE
      await this.setState({ loading: false, multiSelectedVehicles: [] , checkedAll: false});
      
      this.generateTableData();

    }).catch(async err => {
      //RESET TABLE
      await this.setState({ loading: false, multiSelectedVehicles: [] , checkedAll: false});  
      this.generateTableData();

    } )
  }

  /**
   * RESET CHECKBOX
   * @returns 
   */
   resetChekboxSelection = async () => {
     await this.setState({multiSelectedVehicles: [], checkedAll: false});
     this.generateTableData()
   }


  render() {
    const { tableHtmlData, formAction, loading, totalLengthWithoutPagination, resultList, page, runPaginationFunc, tableHeaders, filterFieldsData, resetFilterBoxPagination, vehicleListPagination, checkedAll, multiSelectedVehicles, filterData } = this.state;  
    return (
      <div>
        <div className={"container-fluid-full"}>
          <FullPageLoader show={loading} />


          <div className="top-sec">
           
            <TopActionBtn componentData={{ multiSelectedVehicles, loading }} bulkInventoryDelete={this.bulkInventoryDelete} bulkInventorySendToInspection={this.bulkInventorySendToInspection} exportXls={this.exportXls} loginType={this.props.loginType} resetChekboxSelection={this.resetChekboxSelection} />
          </div>
          <div className="vehicle-listing-box">
            <FilterBox filterBox={{ resetFilterBoxPagination, filterFields: filterFieldsData, page: page, runPaginationFunc: runPaginationFunc, loading: loading, 'tableData': resultList }} action='vehicleList' searchResults={this.handleSearchedData} sendRequestDataToParent={this.handleRequestData} />
          </div>

          <div className="vechile-table-data">
            <FilterTable filterTableData={{ formAction, loading: loading, totalLengthWithoutPagination, tableData: tableHtmlData, page, runPaginationFunc, tableHeaders, checkedAll }} parentCallback={this.callbackFunction} checkedAll={this.handledCheckedAll} loginType={this.props.loginType}/>
            <div className="pagination-outer">
              <Pagination paginateData={{ page, runPaginationFunc, loading: loading, 'tableData': resultList, totalLengthWithoutPagination, vehicleListPagination, filterData }} action={formAction} sendRequestDataToParent={this.handleRequestData} checkedAllStatus />
            </div>

          </div>
        </div>

        <div className="upload-vehicle-popup single-vechile-popup">
          <Modal show={this.state.zipImageUpload} handleClose={this.hideModalZipUpload} >
            <div className="modal-header">
              <h2>{this.props.t('pages.vehicle.list.image_upload')}</h2>
            </div>
            <div className="modal-body" >
              <div className="upload-image-panel upload-heading">
                <h3>{this.props.t('pages.vehicle.list.upload_vehicle_image')}</h3>
                <ImageUploadForInventory closeModalInventoryPopup={this.closeModalInventoryPopup} inventoryData={this.state.inventoryData} loading={loading} zipImageUpload={this.state.zipImageUpload} />
              </div>
            </div>
          </Modal>
        </div>


      </div>
    )
  }
}

const mapStateToProps = state => { 
  return {
    allVehicleList: state.vehicle.vehicleList || {},
    vehicleListPagination: state.vehicle.vehicleListPagination || {},
    authUser: state.auth.authUser || {},
    loginType: state.auth.authUser.login_type || '',
    auctionVehicleFilterList: state.common.auctionVehicleFilterList || {}
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: {
      getVehicleList: bindActionCreators(
        VehicleActions.getVehicleList,
        dispatch
      ),
      exportXls: bindActionCreators(
        VehicleActions.exportVehicleData,
        dispatch
      ),
      resetVehicleListFromState: bindActionCreators(
        VehicleActions.resetVehicleListFromState,
        dispatch
      ),
      bulkInventoryDelete: bindActionCreators(
        VehicleActions.bulkInventoryDelete,
        dispatch
      ),
      bulkInventorySendToInspection: bindActionCreators(
        VehicleActions.bulkInventorySendToInspection,
        dispatch
      )
    }
  }
}

export default withTranslation('common')(withRouter(connect(mapStateToProps, mapDispatchToProps)(Vehicle)));