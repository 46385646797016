import React, { Component } from 'react';
import { DateTimePicker, EndDateTimePicker } from './DatePicker'
import Pagination from "./pagination"
import Select from 'react-select';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import BiddingDetailPopup from './BiddingDetailPopup'


const BankAuction = [
    { value: 'Bank', label: 'Bank' },
    { value: 'Bank1', label: 'Bank1' },
    { value: 'Bank2', label: 'Bank2' },
    { value: 'Bank3', label: 'Bank3' },
];

const MakeModel = [
    { value: 'Make & Model', label: 'Make & Model' },
    { value: 'Make & Model1', label: 'Make & Model1' },
    { value: 'Make & Model2', label: 'Make & Model2' },
    { value: 'Make & Model3', label: 'Make & Model3' },
];

const Seller = [
    { value: 'Seller', label: 'Seller' },
    { value: 'Seller1', label: 'Seller1' },
    { value: 'Seller2', label: 'Seller2' },
    { value: 'Seller3', label: 'Seller3' },
];

const City = [
    { value: 'Jaipur', label: 'Jaipur' },
    { value: 'Delhi', label: 'Delhi' },
    { value: 'Puna', label: 'Puna' },
];
const BidType = [
    { value: 'BidType', label: 'BidType' },
    { value: 'BidType1', label: 'BidType1' },
    { value: 'BidType2', label: 'BidType2' },
    { value: 'BidType3', label: 'BidType3' },
];

class AuctionMonitoring extends Component {
    constructor(props) {
        super(props);
        console.log(props)

        this.state = {
            BiddingPopup: false,
        };
    }

    handleChangeBankAuction = (BankAuctionOption) => {
        this.setState({ BankAuctionOption }
        );
    };
    handleChangeBankAuction = (MakeModelOption) => {
        this.setState({ MakeModelOption }
        );
    };
    handleChangeBankAuction = (SellerOption) => {
        this.setState({ SellerOption }
        );
    };
    handleChangeBankAuction = (CityOption) => {
        this.setState({ CityOption }
        );
    };
    handleChangeBankAuction = (BidTypeOption) => {
        this.setState({ BidTypeOption }
        );
    };
    showModalBiddingPopup = () => {
        this.setState({ BiddingPopup: true });
        document.body.classList.add("overflow-hidden");
    }

    hideModalBiddingPopup = () => {
        this.setState({ BiddingPopup: false });
        document.body.classList.remove("overflow-hidden");
    }


    render() {
        const { BankAuctionOption } = this.state;
        const { MakeModelOption } = this.state;
        const { SellerOption } = this.state;
        const { CityOption } = this.state;
        const { BidTypeOption } = this.state;
        return (
            <div className="container-fluid-full">
                <div class="top-sec">
                    <div class="top-heading-action-sec auction-top-heading">
                        <h1>Auction Monitoring</h1>
                        <fieldset class=" search-bx search-top-bar">
                            <input id="" type="text" placeholder="Search by VID, Reg. No. or Name" name="" class="form-input" />
                            <i class="ic-search"></i>
                        </fieldset>
                    </div>
                </div>
                <div className="top-filter-sec">
                    <fieldset class=" multiselect-dropDown">
                        <div class="material">
                            <ReactMultiSelectCheckboxes
                                options={BankAuction}
                                value={BankAuctionOption}
                                placeholderButtonLabel="Bank Auction"
                            />
                        </div>
                    </fieldset>
                    <fieldset class=" multiselect-dropDown">
                        <div class="material">

                            <ReactMultiSelectCheckboxes
                                options={MakeModel}
                                value={MakeModelOption}
                                placeholderButtonLabel="Make & Model"
                            />
                        </div>
                    </fieldset>
                    <fieldset class=" multiselect-dropDown">
                        <div class="material">


                            <ReactMultiSelectCheckboxes
                                options={Seller}
                                value={SellerOption}
                                placeholderButtonLabel="Seller"
                            />
                        </div>
                    </fieldset>
                    <fieldset class=" multiselect-dropDown">
                        <div class="material">

                            <ReactMultiSelectCheckboxes
                                options={City}
                                value={CityOption}
                                placeholderButtonLabel="City"
                            />
                        </div>
                    </fieldset>
                    <fieldset class=" select-drop-down">
                        <div class="material">
                            <Select
                                value={BidTypeOption}
                                onChange={this.handleChangeBidType}
                                options={BidType}
                                placeholder="Bid Type"

                            />
                        </div>
                    </fieldset>
                    <div class="btn-submit-reset">
                        <button class="btn-primary">Submit</button>
                        <button class="btn-reset">Reset</button>
                    </div>
                </div>
                <div className="auction-monitoring-data-table">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <li className="custom-control custom-checkbox" >
                                        <input id="all" type="checkbox" className="custom-control-input" />
                                        <label for="all" className="custom-control-label"></label>
                                    </li>
                                </th>
                                <th>VID</th>
                                <th>MMV Details</th>
                                <th>Seller Details</th>
                                <th>Auction Name</th>
                                <th>End Time</th>
                                <th>Highest Bid</th>
                                <th>No. of Bids</th>
                                <th>Bidders</th>
                                <th>REB Price</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <li className="custom-control custom-checkbox" onClick={this.showModalReplicate}>
                                        <input id="all" type="checkbox" className="custom-control-input" />
                                        <label for="all" className="custom-control-label"></label>
                                    </li>
                                </td>
                                <td>
                                    5LATD8
                                </td>
                                <td>
                                    <div className="auction-car-detail">
                                        <a href="">Ford Freestyle Titanium</a>
                                        <span>HR 87B 9994</span>
                                    </div>
                                </td>
                                <td>
                                    <div className="saller-detail">
                                        <span>BMW Financial Services</span>
                                        <span>9718905471</span>
                                    </div>
                                </td>
                                <td>
                                    BMW Financial Service 4W All India
                                </td>
                                <td>20/08/2021, 9 PM </td>
                                <td>
                                    <a href="#">
                                        Rp 24.000.000
                                        Sumit Kumar
                                    </a>
                                </td>
                                <td>
                                    <a href="#">56</a>
                                </td>
                                <td>
                                    <a href="#">8</a>
                                </td>
                                <td>
                                    <div className="red-price">
                                        <ul>
                                            <li>Reserved: Rp 240.000.000</li>
                                            <li>Expected: Rp 600.000.000 </li>
                                            <li> Base: Rp 450.000.000</li>

                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    Open
                                </td>
                                <td>
                                    <button className="btn-view">
                                        <button className="btn-line">View</button>
                                    </button>
                                </td>
                            </tr>
                            <tr className="bid-nill">
                                <td>
                                    <li className="custom-control custom-checkbox">
                                        <input id="all" type="checkbox" className="custom-control-input" />
                                        <label for="all" className="custom-control-label"></label>
                                    </li>
                                </td>
                                <td>
                                    5LATD8
                                </td>
                                <td>
                                    <div className="auction-car-detail">
                                        <a href="">Ford Freestyle Titanium</a>
                                        <span>HR 87B 9994</span>
                                    </div>
                                </td>
                                <td>
                                    <div className="saller-detail">
                                        <span>BMW Financial Services</span>
                                        <span>9718905471</span>
                                    </div>
                                </td>
                                <td>
                                    BMW Financial Service 4W All India
                                </td>
                                <td>20/08/2021, 9 PM </td>
                                <td>
                                    <a onClick={this.showModalBiddingPopup} >
                                        Rp 24.000.000
                                        Sumit Kumar
                                    </a>
                                </td>
                                <td>
                                    <a href="#">56</a>
                                </td>
                                <td>
                                    <a href="#">8</a>
                                </td>
                                <td>
                                    <div className="red-price">
                                        <ul>
                                            <li>Reserved: Rp 240.000.000</li>
                                            <li>Expected: Rp 600.000.000 </li>
                                            <li> Base: Rp 450.000.000</li>

                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    Open
                                </td>
                                <td>
                                    <button className="btn-view">
                                        <button className="btn-line">View</button>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <li className="custom-control custom-checkbox" onClick={this.showModalReplicate}>
                                        <input id="all" type="checkbox" className="custom-control-input" />
                                        <label for="all" className="custom-control-label"></label>
                                    </li>
                                </td>
                                <td>
                                    5LATD8
                                </td>
                                <td>
                                    <div className="auction-car-detail">
                                        <a href="">Ford Freestyle Titanium</a>
                                        <span>HR 87B 9994</span>
                                    </div>
                                </td>
                                <td>
                                    <div className="saller-detail">
                                        <span>BMW Financial Services</span>
                                        <span>9718905471</span>
                                    </div>
                                </td>
                                <td>
                                    BMW Financial Service 4W All India
                                </td>
                                <td>20/08/2021, 9 PM </td>
                                <td>
                                    <a href="#">
                                        Rp 24.000.000
                                        Sumit Kumar
                                    </a>
                                </td>
                                <td>
                                    <a href="#">56</a>
                                </td>
                                <td>
                                    <a href="#">8</a>
                                </td>
                                <td>
                                    <div className="red-price">
                                        <ul>
                                            <li>Reserved: Rp 240.000.000</li>
                                            <li>Expected: Rp 600.000.000 </li>
                                            <li> Base: Rp 450.000.000</li>

                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    Open
                                </td>
                                <td>
                                    <button className="btn-view">
                                        <button className="btn-line">View</button>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <li className="custom-control custom-checkbox" onClick={this.showModalReplicate}>
                                        <input id="all" type="checkbox" className="custom-control-input" />
                                        <label for="all" className="custom-control-label"></label>
                                    </li>
                                </td>
                                <td>
                                    5LATD8
                                </td>
                                <td>
                                    <div className="auction-car-detail">
                                        <a href="">Ford Freestyle Titanium</a>
                                        <span>HR 87B 9994</span>
                                    </div>
                                </td>
                                <td>
                                    <div className="saller-detail">
                                        <span>BMW Financial Services</span>
                                        <span>9718905471</span>
                                    </div>
                                </td>
                                <td>
                                    BMW Financial Service 4W All India
                                </td>
                                <td>20/08/2021, 9 PM </td>
                                <td>
                                    <a href="#">
                                        Rp 24.000.000
                                        Sumit Kumar
                                    </a>
                                </td>
                                <td>
                                    <a href="#">56</a>
                                </td>
                                <td>
                                    <a href="#">8</a>
                                </td>
                                <td>
                                    <div className="red-price">
                                        <ul>
                                            <li>Reserved: Rp 240.000.000</li>
                                            <li>Expected: Rp 600.000.000 </li>
                                            <li> Base: Rp 450.000.000</li>

                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    Open
                                </td>
                                <td>
                                    <button className="btn-view">
                                        <button className="btn-line">View</button>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <li className="custom-control custom-checkbox" onClick={this.showModalReplicate}>
                                        <input id="all" type="checkbox" className="custom-control-input" />
                                        <label for="all" className="custom-control-label"></label>
                                    </li>
                                </td>
                                <td>
                                    5LATD8
                                </td>
                                <td>
                                    <div className="auction-car-detail">
                                        <a href="">Ford Freestyle Titanium</a>
                                        <span>HR 87B 9994</span>
                                    </div>
                                </td>
                                <td>
                                    <div className="saller-detail">
                                        <span>BMW Financial Services</span>
                                        <span>9718905471</span>
                                    </div>
                                </td>
                                <td>
                                    BMW Financial Service 4W All India
                                </td>
                                <td>20/08/2021, 9 PM </td>
                                <td>
                                    <a href="#">
                                        Rp 24.000.000
                                        Sumit Kumar
                                    </a>
                                </td>
                                <td>
                                    <a href="#">56</a>
                                </td>
                                <td>
                                    <a href="#">8</a>
                                </td>
                                <td>
                                    <div className="red-price">
                                        <ul>
                                            <li>Reserved: Rp 240.000.000</li>
                                            <li>Expected: Rp 600.000.000 </li>
                                            <li> Base: Rp 450.000.000</li>

                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    Open
                                </td>
                                <td>
                                    <button className="btn-view">
                                        <button className="btn-line">View</button>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <li className="custom-control custom-checkbox" onClick={this.showModalReplicate}>
                                        <input id="all" type="checkbox" className="custom-control-input" />
                                        <label for="all" className="custom-control-label"></label>
                                    </li>
                                </td>
                                <td>
                                    5LATD8
                                </td>
                                <td>
                                    <div className="auction-car-detail">
                                        <a href="">Ford Freestyle Titanium</a>
                                        <span>HR 87B 9994</span>
                                    </div>
                                </td>
                                <td>
                                    <div className="saller-detail">
                                        <span>BMW Financial Services</span>
                                        <span>9718905471</span>
                                    </div>
                                </td>
                                <td>
                                    BMW Financial Service 4W All India
                                </td>
                                <td>20/08/2021, 9 PM </td>
                                <td>
                                    <a href="#">
                                        Rp 24.000.000
                                        Sumit Kumar
                                    </a>
                                </td>
                                <td>
                                    <a href="#">56</a>
                                </td>
                                <td>
                                    <a href="#">8</a>
                                </td>
                                <td>
                                    <div className="red-price">
                                        <ul>
                                            <li>Reserved: Rp 240.000.000</li>
                                            <li>Expected: Rp 600.000.000 </li>
                                            <li> Base: Rp 450.000.000</li>

                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    Open
                                </td>
                                <td>
                                    <button className="btn-view">
                                        <button className="btn-line">View</button>
                                    </button>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                    <div className="pagination-outer">
                        <Pagination />

                    </div>

                </div>
                <div className="bidding-detail-popup">
                    <Modal show={this.state.BiddingPopup} handleClose={this.hideModalBiddingPopup} >
                        <BiddingDetailPopup />
                    </Modal>
                </div>
            </div>




        );
    }
}

const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? 'modal display-block' : 'modal display-none';

    return (
        <div className={showHideClassName}>
            <section className='modal-main'>
                {children}
                <button
                    onClick={handleClose}
                    className="close_icn"
                >
                    <i className="ic-clearclose"></i>
                </button>

            </section>
        </div>
    );
};



export default AuctionMonitoring;