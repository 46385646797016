import React, { Component } from 'react';
import Select from 'react-select';
import { CalendraSimple } from './DatePicker'

const VehicleType = [
    { value: '4 Wheeler', label: '4 Wheeler' },
    { value: '3 Wheeler', label: '3 Wheeler' },
    { value: '2 Wheeler', label: '2 Wheeler' },
];


const MakeModel = [
    { value: 'Ford Freestyle', label: 'Ford Freestyle' },
    { value: 'Ford Freestyle1', label: 'Ford Freestyle1' },
    { value: 'Ford Freestyle', label: 'Ford Freestyle' },
];
const Varient = [
    { value: 'Titanium', label: 'Titanium' },
    { value: 'Titanium1', label: 'Titanium1' },
    { value: 'Titanium2', label: 'Titanium2' },
];

const Fuel = [
    { value: 'Petrol', label: 'Petrol' },
    { value: 'Diesel', label: 'Diesel' },
    { value: 'CNG', label: 'CNG' },
];

const Transmission = [
    { value: 'Manual', label: 'Manual' },
    { value: 'Automatic', label: 'Automatic' },
];

const Color = [
    { value: 'Red', label: 'Red' },
    { value: 'White', label: 'White' },
    { value: 'Blue', label: 'Blue' },
];
const Ownership = [
    { value: '1st Owner', label: '1st Owner' },
    { value: '2st Owner', label: '2st Owner' },
    { value: '3st Owner', label: '3st Owner' },
];
const OwnerType = [
    { value: 'Corporate', label: 'Corporate' },
    { value: 'Private', label: 'Private' },
    { value: '3st Owner', label: '3st Owner' },
];
const ChassieNumber = [
    { value: '1234567890', label: '1234567890' },
    { value: '2234567890', label: '2234567890' },
    { value: '3234567890', label: '3234567890' },
];
const EngineNumber = [
    { value: '1234567888', label: '1234567888' },
    { value: '2234567893', label: '2234567893' },
    { value: '3234567890', label: '3234567890' },
];



const QCStatus = [
    { value: 'Pending', label: 'Pending' },
    { value: 'Approve', label: 'Approve' },
    { value: 'Inprocess', label: 'Inprocess' },
];

const DetailsUpdated = [
    { value: 'Updated', label: 'Updated' },
    { value: 'Pending', label: 'Pending' },
    { value: 'Inprocess', label: 'Inprocess' },
];

const Inspection = [
    { value: 'Under Inspection', label: 'Under Inspection' },
    { value: 'Pending', label: 'Pending' },
    { value: 'Under Process', label: 'Under Process' },
];

class EditVechileform extends Component {
    constructor(props) {
        super(props);
        console.log(props)

        this.state = {
            selectedVehicleType: null,
            selectedMakeModel: null,
            selectedVarint: null,
            selectedFuel: null,
            selectedTransmission: null,
            selectedColor: null,
            selectedOwnership: null,
            selectedOwnerType: null,
            selectedChassieNumber: null,
            selectedQCStatus: null,
            selectedDetailsUpdated: null,
            selectedInspection: null,
            selectedEngineNumber: null,
            ViewTimeLine: false,
        };
        this.divRef = React.createRef()


    }




    handleChangeVehicleType = (selectedVehicleType) => {
        this.setState({ selectedVehicleType }

        );
        // document.body.classList.add("overflow-hidden");
        this.divRef.current.classList.add('active-label')


    };
    handleChangeMakeModel = (selectedMakeModel) => {
        this.setState({ selectedMakeModel }
        );

    };
    handleChangeVarient = (selectedVarint) => {
        this.setState({ selectedVarint }
        );

    };
    handleChangeFuel = (selectedFuel) => {
        this.setState({ selectedFuel }
        );
    };



    handleChangeTransmission = (selectedTransmission) => {
        this.setState({ selectedTransmission }
        );

    };
    handleChangeColor = (selectedColor) => {
        this.setState({ selectedColor }
        );
    };

    handleChangeOwnership = (selectedOwnership) => {
        this.setState({ selectedOwnership }
        );
    };

    handleChangeOwnerType = (selectedOwnerType) => {
        this.setState({ selectedOwnerType }
        );
    };

    handleChangeChassieNumber = (selectedChassieNumber) => {
        this.setState({ selectedChassieNumber }
        );
    };

    handleChangeQCStatus = (selectedQCStatus) => {
        this.setState({ selectedQCStatus }
        );
    };

    handleChangeDetailsUpdated = (selectedDetailsUpdated) => {
        this.setState({ selectedDetailsUpdated }
        );
    };

    handleChangeInspection = (selectedInspection) => {
        this.setState({ selectedInspection }
        );
    };

    handleChangeInspection = (selectedInspection) => {
        this.setState({ selectedInspection }
        );
    };
    handleChangeEngineNumber = (selectedEngineNumber) => {
        this.setState({ selectedEngineNumber }
        );
    };

    showModalViewTimeLine = () => {
        this.setState({ ViewTimeLine: true });
        document.body.classList.add("overflow-hidden");
    }

    hideModalViewTimeLine = () => {
        this.setState({ ViewTimeLine: false });
        document.body.classList.remove("overflow-hidden");
    }



    render() {
        const { selectedVehicleType } = this.state;
        const { selectedMakeModel } = this.state;
        const { selectedVarint } = this.state;
        const { selectedFuel } = this.state;

        const { selectedTransmission } = this.state;
        const { selectedColor } = this.state;
        const { selectedOwnership } = this.state;
        const { selectedOwnerType } = this.state;
        const { selectedChassieNumber } = this.state;
        const { selectedEngineNumber } = this.state;

        const { selectedQCStatus } = this.state;
        const { selectedDetailsUpdated } = this.state;
        const { selectedInspection } = this.state;

        return (
            <div>
                <div className="edit-vechile-detail-form">
                    <h2>Basic Details</h2>
                    <div className="edit-form row">
                        <fieldset class="form-select-bx col-md-4">
                            <div class="material">
                                <Select
                                    value={selectedVehicleType}
                                    onChange={this.handleChangeVehicleType}
                                    options={VehicleType}
                                    placeholder=""

                                />
                                <label data-label="Vehicle Type" class="form-label"></label>
                            </div>
                        </fieldset>
                        <fieldset class="form-select-bx col-md-4">
                            <div class="material animation-effect" ref={this.divRef}>
                                <Select
                                    value={selectedMakeModel}
                                    onChange={this.handleChangeMakeModel}
                                    options={MakeModel}
                                    placeholder=""
                                />
                                <label data-label="Make & Model" class="form-label"></label>
                            </div>
                        </fieldset>
                        <fieldset class="form-select-bx col-md-4">
                            <div class="material">
                                <Select
                                    value={selectedVarint}
                                    onChange={this.handleChangeVarient}
                                    options={Varient}
                                    placeholder=""
                                />
                                <label data-label="Variant" class="form-label"></label>
                            </div>
                        </fieldset>
                        <fieldset class="form-select-bx col-md-4">
                            <div class="material">
                                <Select
                                    value={selectedFuel}
                                    onChange={this.handleChangeFuel}
                                    options={Fuel}
                                    placeholder="Fuel Type"
                                />
                                <label data-label="Fuel Type" class="form-label"></label>
                            </div>
                        </fieldset>
                        <fieldset class="form-select-bx col-md-4">
                            <div class="material">
                                <Select
                                    value={selectedTransmission}
                                    onChange={this.handleChangeTransmission}
                                    options={Transmission}
                                    placeholder="Transmission"
                                />
                                <label data-label="Transmission" class="form-label"></label>
                            </div>
                        </fieldset>

                        <fieldset class="datepicker-cal col-md-4">
                            <div class="material">
                                <CalendraSimple />
                                <label data-label="Make Year" class="form-label"></label>
                            </div>
                        </fieldset>

                        <fieldset class="form-select-bx col-md-4">
                            <div class="material">
                                <Select
                                    value={selectedColor}
                                    onChange={this.handleChangeColor}
                                    options={Color}
                                    placeholder="Color"
                                />
                                <label data-label="Color" class="form-label"></label>
                            </div>
                        </fieldset>
                        <fieldset class="form-select-bx col-md-4">
                            <div class="material">
                                <Select
                                    value={selectedOwnership}
                                    onChange={this.handleChangeOwnership}
                                    options={Ownership}
                                    placeholder="Ownership"
                                />
                                <label data-label="Ownership" class="form-label"></label>
                            </div>
                        </fieldset>

                        <fieldset class="form-select-bx col-md-4">
                            <div class="material">
                                <Select
                                    value={selectedOwnerType}
                                    onChange={this.handleChangeOwnerType}
                                    options={OwnerType}
                                    placeholder="Owner Type"
                                />
                                <label data-label="Owner Type" class="form-label"></label>
                            </div>
                        </fieldset>
                        <fieldset class="datepicer col-md-4">
                            <div class="material">
                                <input id="" type="text" placeholder=" " name="Odometer Reading" class="form-input" />

                                <label data-label="Odometer Reading" class="form-label"></label>
                            </div>
                        </fieldset>


                        <fieldset class="form-select-bx col-md-4">
                            <div class="material">
                                <Select
                                    value={selectedEngineNumber}
                                    onChange={this.handleChangeEngineNumber}
                                    options={EngineNumber}
                                    placeholder="Engine Number"
                                />
                                <label data-label="Engine Number" class="form-label"></label>
                            </div>
                        </fieldset>

                        <fieldset class="form-select-bx col-md-4">
                            <div class="material">
                                <Select
                                    value={selectedChassieNumber}
                                    onChange={this.handleChangeChassieNumber}
                                    options={ChassieNumber}
                                    placeholder="Chassie Number"
                                />
                                <label data-label="Chassie Number" class="form-label"></label>
                            </div>
                        </fieldset>
                    </div>


                    <h2>Seller Details</h2>
                    <div className="edit-form row">

                        <fieldset class="datepicer col-md-4">
                            <div class="material">
                                <input id="" type="text" placeholder=" " name="Seller Name" class="form-input" />

                                <label data-label="Seller Name" class="form-label"></label>
                            </div>
                        </fieldset>
                        <fieldset class="datepicer col-md-4">
                            <div class="material">
                                <input id="" type="text" placeholder=" " name="Odometer Reading" class="form-input" />

                                <label data-label="Contact Person Name" class="form-label"></label>
                            </div>
                        </fieldset>
                        <fieldset class="datepicer col-md-4">
                            <div class="material">
                                <input id="" type="text" placeholder=" " name="Odometer Reading" class="form-input" />

                                <label data-label="Email" class="form-label"></label>
                            </div>
                        </fieldset>
                        <fieldset class="datepicer col-md-4">
                            <div class="material">
                                <input id="" type="text" placeholder="" name="Odometer Reading" class="form-input" />

                                <label data-label="Mobile No." class="form-label"></label>
                            </div>
                        </fieldset>
                    </div>
                    <div className="Summary-heading">
                        <h2>Summary Status</h2>
                        <button className="btn-line" onClick={this.showModalViewTimeLine}>
                            <i className="ic-access_alarms"></i>
                            <span>View Timeline</span></button>
                    </div>
                    <div className="edit-form row">

                        <fieldset class="form-select-bx col-md-4">
                            <div class="material">
                                <Select
                                    value={selectedQCStatus}
                                    onChange={this.handleChangeQCStatus}
                                    options={QCStatus}
                                    placeholder="QC Status"
                                />
                                <label data-label="QC Status" class="form-label"></label>
                            </div>
                        </fieldset>

                        <fieldset class="form-select-bx col-md-4">
                            <div class="material">
                                <Select
                                    value={selectedDetailsUpdated}
                                    onChange={this.handleChangeDetailsUpdated}
                                    options={DetailsUpdated}
                                    placeholder="Details Updated"
                                />
                                <label data-label="Details Updated" class="form-label"></label>
                            </div>
                        </fieldset>

                        <fieldset class="form-select-bx col-md-4">
                            <div class="material">
                                <Select
                                    value={selectedInspection}
                                    onChange={this.handleChangeInspection}
                                    options={ChassieNumber}
                                    placeholder="Inspection"
                                />
                                <label data-label="Inspection" class="form-label"></label>
                            </div>
                        </fieldset>
                    </div>
                    <div className="edit-form">

                        <h2>Remarks</h2>
                        <fieldset class="">
                            <div class="material">
                                <textarea placeholder="Hello, Your Comment"></textarea>
                            </div>
                        </fieldset>
                    </div>
                    <div className="btn-submit-cancel">
                        <button className="btn-primary">Update</button>
                        <button className="btn-line">Cancel</button>
                    </div>
                </div>

                <div className="view-timeline-popup">
                    <Modal show={this.state.ViewTimeLine} handleClose={this.hideModalViewTimeLine} >
                        <div className="modal-header">
                            <h2>Timeline</h2>
                        </div>
                        <div className="modal-body" >
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <span className="date">28 Feb</span>
                                            <span className="time">28 Feb</span>
                                        </td>
                                        <td>
                                            <span className="heading">Disbursement</span>
                                            <span className="Subheading">paid to Dealer</span>
                                        </td>
                                        <td>Priscilla Putri Maharani</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="date">28 Feb</span>
                                            <span className="time">28 Feb</span>
                                        </td>
                                        <td>
                                            <span className="heading">Disbursement</span>
                                            <span className="Subheading">paid to Dealer</span>
                                        </td>
                                        <td>Priscilla Putri Maharani</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="date">28 Feb</span>
                                            <span className="time">28 Feb</span>
                                        </td>
                                        <td>
                                            <span className="heading">Disbursement</span>
                                            <span className="Subheading">paid to Dealer</span>
                                        </td>
                                        <td>Priscilla Putri Maharani</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="date">28 Feb</span>
                                            <span className="time">28 Feb</span>
                                        </td>
                                        <td>
                                            <span className="heading">Disbursement</span>
                                            <span className="Subheading">paid to Dealer</span>
                                        </td>
                                        <td>Priscilla Putri Maharani</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="date">28 Feb</span>
                                            <span className="time">28 Feb</span>
                                        </td>
                                        <td>
                                            <span className="heading">Disbursement</span>
                                            <span className="Subheading">paid to Dealer</span>
                                        </td>
                                        <td>Priscilla Putri Maharani</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="date">28 Feb</span>
                                            <span className="time">28 Feb</span>
                                        </td>
                                        <td>
                                            <span className="heading">Disbursement</span>
                                            <span className="Subheading">paid to Dealer</span>
                                        </td>
                                        <td>Priscilla Putri Maharani</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="date">28 Feb</span>
                                            <span className="time">28 Feb</span>
                                        </td>
                                        <td>
                                            <span className="heading">Disbursement</span>
                                            <span className="Subheading">paid to Dealer</span>
                                        </td>
                                        <td>Priscilla Putri Maharani</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Modal>
                </div>

            </div>


        );
    }
}

const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? 'modal display-block' : 'modal display-none';

    return (
        <div className={showHideClassName}>
            <section className='modal-main'>
                {children}
                <button
                    onClick={handleClose}
                    className="close_icn"
                >
                    <i className="ic-clearclose"></i>
                </button>
            </section>
        </div>
    );
};



export default EditVechileform;