import React, { useState, useEffect } from 'react';
import { propTypes } from 'react-bootstrap/esm/Image';
import FilterBox from '../../helpers/filter-box';



const AuctionMonitorFilter = (fliterArgs) => {
    const [bankAuctionOption, setBankAuctionOption] = useState('');
    const [makeModelOption, setMakeModelOption] = useState('');
    const [sellerOption, setSellerOption] = useState('');
    const [cityOption, setCityOption] = useState('');
    const [bidTypeOption, setBidTypeOption] = useState('');

    const[filterFieldsData, setFilterFieldsData] = useState([        
        { name: 'bank_auction', label: '', type: 'multiselect', showCheckBox: true, option: [], value: '', placeholder: 'pages.auction.monitoring.auction_name', createLabelRef: true, isVisible: true },
        { name: 'car_make', label: '', type: 'multiselect', showCheckBox: true, option: [], value: '', placeholder: 'pages.auction.monitoring.make', createLabelRef: true, isVisible: true },
        { name: 'car_model', label: '', type: 'multiselect', showCheckBox: true, option: [], value: '', placeholder: 'pages.auction.monitoring.model', createLabelRef: true, isVisible: true },
        { name: 'dealer_ids', label: '', type: 'multiselect', showCheckBox: true, option: [], value: '', placeholder: 'pages.auction.monitoring.seller', createLabelRef: true, isVisible: true },
        { name: 'auction_city', label: '', type: 'multiselect', showCheckBox: true, option: [], value: '', placeholder: 'pages.auction.monitoring.city', createLabelRef: true, isVisible: true },
        { name: 'bid_type', label: '', type: 'select', showCheckBox: true, option: [], value: '', placeholder: 'pages.auction.monitoring.bid_type', createLabelRef: true, isVisible: true },
        { name: 'status', label: '', type: 'select', showCheckBox: true, option: [], value: '', placeholder: 'pages.auction.monitoring.status', createLabelRef: true, isVisible: true }
    ]);

    const handleRequest = async(params)=>{
        fliterArgs.searchParams(params); 
        if(params && params.action =='resetAll'){
            setSearch('');
        }
    }
    const [search,setSearch] = useState();  
    return (
        <div>
            <div className="top-sec">
                <div className="top-heading-action-sec auction-top-heading">
                    <h1>Auction Monitoring</h1>
                    <fieldset className=" search-bx search-top-bar">
                        <input id="" type="text" placeholder="Search by VID, Reg. No. or Name" name="search" className="form-input" value={search} onChange={(e)=>setSearch(e.target.value)} />
                        <i className="ic-search"></i>
                    </fieldset>
                </div>
            </div>            
            <FilterBox filterBox={{filterFields: filterFieldsData}} sendRequestDataToParent={handleRequest} searchValue={search}/>
        </div>
    );


}


export default AuctionMonitorFilter;