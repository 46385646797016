import secureStorage from './../config/encrypt';
import _ from 'lodash';
import { default as config } from './config';
/**
 * CHECK USER HAS ACCESS TO ROUTE
 * @param {string: route to match} routerPath 
 * @param {string: user assigned menu object in string} stringData 
 * @returns 
 */
export const isUserHasAccessPage = (routerPath='', stringData='') => {
    let currentPath = [];
    let authUserInfo = secureStorage.getItem('authUserInfo');
    let stringMenuData = stringData ? stringData : ((authUserInfo && authUserInfo['headerMenu']) || '') //else get auth user menus
    
    //CHECK FROM URL
    if(routerPath === ''){
      currentPath = window.location.pathname.split('/');
      currentPath = currentPath.filter(path=> path) //FILTER URL PATH

    }else{ //CHECK MANUAL ROUTE
      currentPath = [routerPath]
    }

    let isAuthorized = true
    if(currentPath.length && stringMenuData){

      //MATCH URL WITH NAME OR ACTION FOR ACTIVE CLASS
      if( stringMenuData.indexOf('"action":"'+currentPath[0]+'"') === -1){
        isAuthorized = false
      }

    }

    return isAuthorized;
}


export const getBase64 = async (file, cb) => {
  let reader = new FileReader();
   reader.readAsDataURL(file);
  
  reader.onload =  () => { 
  let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
    if ((encoded.length % 4) > 0) {
      encoded += '='.repeat(4 - (encoded.length % 4));
    }
  cb(encoded)
  };
  reader.onerror =  (error) => {
      cb(error);
  };
}

export const dateFormat = (date, format) => {
    let extractDate = new Date(date).toISOString().split('T')[0]
    extractDate = extractDate.split('-');
    let finalDate = ''
    switch (format) {
      case 'MM/YYYY':
        finalDate = extractDate[1]+'/'+extractDate[2]+'/'+extractDate[0]
        break;
      case 'DD-MM-YYYY':
        finalDate = extractDate[2]+'-'+extractDate[1]+'-'+extractDate[0]
        break;
    
      default:
        finalDate = extractDate
        break;
    }

    return finalDate;
}


export const regValidYears = () => {
  let regValidYears = []
  for(let year=new Date().getFullYear()+5; year>=2000;year--){
    regValidYears.push({value: year, label: year})
  }
  return regValidYears;
}

export const getDataInKeyValueFormat = (params,keyToFind='', groupByKey=null, diffUnique=null) => {
  const arrObj = [];
  let arrObjNew = [];
  _.forEach((params),(data) => {
      let tempObj={};
      tempObj.value = (typeof data.key !== 'undefined') ? data.key : data.id;
      tempObj.label = ((data.value) ? data.value : ((data.key) ? data.key : ((data.make) ? data.make : ((data.organization) ? data.organization : (data.name || data.gcd_code))))) || data.key || data.id;
      if(groupByKey){
          tempObj['groupByLabel'] = (groupByKey === 'sub_status_name') ? data['status_name']+' - '+data[groupByKey] : data[groupByKey];
          
      }
      arrObj.push(tempObj);
  });

  arrObjNew = arrObj;
  if(diffUnique){
      arrObjNew = [];
      arrObjNew = arrObj.map(el=> { 
          if((arrObj.filter(elm=> (elm.label === el.label && elm.value !== el.value))).length){
              return {...el, label: el.groupByLabel+ ' - ' + el.label}
          }else{
              return el;
          }
      });
  }

  return arrObjNew;
}

export const getRegionName = async(params) => {
  let region = 'North';
  if(+params === 2){
    region = 'South';
  }else if(+params === 3) {
    region = 'East';
  }else if(+params === 4) {
    region = 'West';
  }
  return region;
}

export const getFormatName = async (params) => {
  let format = 'Open';
  if (+params === 1) {
    format = 'Open';
  } else if (+params === 2) {
    format = 'Private';
  } else if (+params === 3) {
    format = 'Close';
  }
  return format;
}

export const getRemainingTime = async (endDateTime) => {
  let remainingTime = '';
  let countDownDate = new Date(endDateTime).getTime();
  let now = new Date().getTime();
  let timeleft = countDownDate - now;

  let days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
  let hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  let minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
  let seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
  if (days > 0) {
    remainingTime += days + 'd ';
  }
  if (hours > 0) {
    remainingTime += hours + 'h ';
  }
  if (minutes > 0) {
    remainingTime += minutes + 'm ';
  }
  if(seconds>=0){
    remainingTime += seconds +'s '
  }
  return (remainingTime)?remainingTime:'NA';
}

export const getVehicleTypeName = async(param)=>{
  switch(param){
    case 1:
      return '2 Wheeler';
    case 2:
      return '3 Wheeler';
    case 3:
      return '4 Wheeler';
    case 4:
      return 'Passenger Carrying Vehicle';
    case 5:
      return 'Commercial vehicle';
    default:
      return '2 Wheeler';
  }
}

export const addCurrencyFormatting = (number, countryCode='') => {
  number = number.toString().replace(/,/g,'')
  return config.constants.CURRENCY_SYMBOL + ' ' + (+number ? new Number(number).toLocaleString("en") : 0);
}

export const removeCurrencyFormatting = (number) => {
  if(number){
  var result = (''+number).replace(/[Rp]+/g,"").replace(/[₱]+/g,"").replace(/[AED]+/g,"").replace(/,/g,"");
  return result.toString().trim()
  }

}
