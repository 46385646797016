import React, { Component } from 'react';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import Select from 'react-select';
const seller = [
    { value: 'Abhishek Kumar (HR 87B 9994)', label: 'Abhishek Kumar (HR 87B 9994)' },
    { value: ' Aastha Goyal (DL 84C 9433)', label: ' Aastha Goyal (DL 84C 9433)' },
    { value: ' Aastha Goyal1 (DL 84C 9433)', label: ' Aastha Goyal1 (DL 84C 9433)' },
    { value: 'Abhishek Kumar2 (HR 87B 9994)', label: 'Abhishek Kumar2 (HR 87B 9994)' },
    { value: ' Aastha Goya5 (DL 84C 9433)', label: ' Aastha Goyal5 (DL 84C 9433)' },
    { value: ' Aastha Goyal4 (DL 84C 9433)', label: ' Aastha Goyal4 (DL 84C 9433)' },
];


const CCto = [
    { value: 'cardekhoauction@oto.com', label: 'cardekhoauction@oto.com' },
    { value: 'cardekho@oto.com', label: 'cardekho@oto.com' },
    { value: 'girnar@oto.com', label: 'girnar@oto.com' },

];
const Template = [
    { value: 'Change in Auction Time', label: 'Change in Auction Time' },
    { value: 'Template', label: 'Template' },
    { value: 'Template1', label: 'Template1' },

];

const Title = [
    { value: 'Title', label: 'Title' },
    { value: 'Title1', label: 'Title1' },
    { value: 'Title2', label: 'Title2' },

];



class ShareDetailPopup extends Component {
    constructor(props) {
        super(props);
        console.log(props)

        this.state = {
            sellerOption: null,
            CCtoOption: null,
        };
    }

    handleChangeseller = (sellerOption) => {
        this.setState({ sellerOption }
        );
    };

    handleChangecc = (CCtoOption) => {
        this.setState({ CCtoOption }
        );
    };
    handleChangeTemplate = (TemplateOption) => {
        this.setState({ TemplateOption }
        );
    };
    handleChangeTitle = (TitleOption) => {
        this.setState({ TitleOption }
        );
    };

    render() {
        const { sellerOption } = this.state;
        const { CCtoOption } = this.state;
        const { TemplateOption } = this.state;
        const { TitleOption } = this.state;
        return (
            <div>
                <div className="modal-header">
                    <h2>Timeline</h2>
                </div>
                <div className="modal-body" >
                    <div className="share-detail-checkbox">
                        <li className="custom-control custom-checkbox">
                            <input id="all" type="checkbox" className="custom-control-input" />
                            <label for="all" className="custom-control-label">SMS</label>
                        </li>
                        <li className="custom-control custom-checkbox">
                            <input id="all" type="checkbox" className="custom-control-input" />
                            <label for="all" className="custom-control-label">Email</label>
                        </li>
                    </div>
                    <div className="mail-form-sec">
                        <fieldset class="multiselect-dropDown buyer-dropdown">
                            <div class="material">
                                <ReactMultiSelectCheckboxes
                                    isMulti
                                    options={seller}
                                    value={sellerOption}
                                    placeholderButtonLabel="Select Buyers"

                                />
                                <label className="label-txt">To</label>
                            </div>
                        </fieldset>
                        <div class="select-item-list">
                            <ul>
                                <li>
                                    <span> Abhishek Kumar (HR 87B 9994)</span>
                                    <i className="ic-clearclose"></i>
                                </li>
                                <li>
                                    <span> Aastha Goyal	(DL 84C 9433)</span>
                                    <i className="ic-clearclose"></i>
                                </li>
                                <li>
                                    <span> Abhishek Kumar (HR 87B 9994)</span>
                                    <i className="ic-clearclose"></i>
                                </li>
                                <li>
                                    <span> + 8 more</span>
                                    <i className="ic-clearclose"></i>
                                </li>
                            </ul>
                        </div>
                        <fieldset class="buyer-dropdown select-drop-down">
                            <div class="material">

                                <Select
                                    value={CCtoOption}
                                    onChange={this.handleChangecc}
                                    options={CCto}
                                    placeholder=""
                                />

                                <label data-label="cc to" class="form-label"></label>
                            </div>
                        </fieldset>
                        <fieldset class="buyer-dropdown select-drop-down">
                            <div class="material">

                                <Select
                                    value={TemplateOption}
                                    onChange={this.handleChangeTemplate}
                                    options={Template}
                                    placeholder=""
                                />
                                <label data-label="Select Template" class="form-label"></label>
                            </div>
                        </fieldset>

                        <fieldset class="buyer-dropdown select-drop-down">
                            <div class="material">

                                <Select
                                    value={TitleOption}
                                    onChange={this.handleChangeTitle}
                                    options={Title}
                                    placeholder=""
                                />
                                <label data-label="Title" class="form-label"></label>
                            </div>
                        </fieldset>
                        <fieldset class="">
                            <div class="material">

                                <textarea className="form-input" placeholder=" "></textarea>
                                <label data-label="Message" class="form-label"></label>
                            </div>
                        </fieldset>
                        <div className="btn-send">
                            <button className="btn-primary">Send</button>
                        </div>
                    </div>
                </div>
            </div>




        );
    }
}



export default ShareDetailPopup;