import React, { Component } from 'react';
import Gallry_img from "../../../webroot/images/gallery_img.png";

class VechileGallery extends Component {
    constructor(props) {
        super(props);
        console.log(props)

        this.state = {


        };
    }

    render() {
        return (
            <div className="vechile-gallery">
                <ul>
                    <li>
                        <img src={Gallry_img} className="" />
                        <i className="ic-clearclose"></i>
                    </li>
                    <li>
                        <img src={Gallry_img} className="" />
                        <i className="ic-clearclose"></i>
                    </li>
                    <li>
                        <img src={Gallry_img} className="" />
                        <i className="ic-clearclose"></i>
                    </li>
                    <li>
                        <img src={Gallry_img} className="" />
                        <i className="ic-clearclose"></i>
                    </li>
                    <li>
                        <img src={Gallry_img} className="" />
                        <i className="ic-clearclose"></i>
                    </li>
                    <li>
                        <img src={Gallry_img} className="" />
                        <i className="ic-clearclose"></i>
                    </li>

                    <li className="add-more-vechile">
                        <i className="ic-add"> </i>
                        <span>Upload More Image</span>

                    </li>
                </ul>
            </div>


        );
    }
}


export default VechileGallery; 