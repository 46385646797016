import React, { useState, useEffect } from "react";
import BiddingDetailPopup from "./BiddingDetailPopup";
import { default as config } from '../../../config/config';
import DateFormat from 'dateformat';
import Pagination from '../../elements/Pagination';
import { useSelector, useDispatch } from 'react-redux'
import { AuctionActions } from '../../../store/action';
import { toast } from 'react-toastify';
import * as HELPER from './../../../config/helper';

function showModalReplicate() {

}


const AuctionMonitorFilterResult = (props) => {
    let bidCarsList = (props.filterTableData.bidCarlist) ? props.filterTableData.bidCarlist : [];
    const [biddingPopup, setBiddingPopup] = useState(false);
    const [runPaginationFunc, setRunPaginationFunc] = useState(props.filterTableData.runPaginationFunc);
    const [totalLengthWithoutPagination, setTotalLengthWithoutPagination] = useState(props.filterTableData.totalLengthWithoutPagination);
    const [page, setPage] = useState(props.filterTableData.page);
    const [loading, setLoading] = useState(props.filterTableData.loading);
    const [isMounted, setIsMounted] = useState(false);
    const [vehicleListPagination, setVehicleListPagination] = useState(props.pagination);
    const [formAction, setFormAction] = useState(props.action);
    const [pagination, setPagination] = useState({});
    const [tab, setTab] = useState(props.tab);
    const [bidcardata, setBidcardata] = useState([]);
    const [viewParams, setViewParams] = useState({});
    const [counter, setCounter] = useState(0);

    useEffect(() => {
        setIsMounted(true)
        return () => {
            setIsMounted(false)
        }
    })
    useEffect(() => {
        setTotalLengthWithoutPagination(props.filterTableData.totalLengthWithoutPagination);
        setVehicleListPagination(props.pagination);
        setFormAction(props.action);      
        
    })
    useEffect(() => {
        if(viewParams && viewParams.car_id && props.realTimeAuctionCarBids && props.realTimeAuctionCarBids.length && props.realTimeAuctionCarBids[0]['car_id'] == viewParams.car_id){
            setBidcardata([...props.realTimeAuctionCarBids, ...bidcardata])
        }

    }, [props.realTimeAuctionCarBids]);


    useEffect(() => {
        if(Object.keys(viewParams).length !== 0 && counter){
            setLoading(true);
            getViewResults(viewParams);
        }        
    }, [counter]);

    const dispatch = useDispatch();
    const showModalBiddingPopup = (params) => {
        setCounter(1);    
        setLoading(true);    
        setBiddingPopup(true);  
        setBidcardata({});
        document.body.classList.add("overflow-hidden");       
        setViewParams(params);         
    }

    const canceledBidFromChild = (params)=>{
        dispatch(AuctionActions.cancelBid({bidIds:params.bidsIds})).then(bidRes => {
            if (bidRes.status == 200) {
                toast.success(bidRes.message)

                //LOAD ALL BIDS
                getViewResults(params)
            }
          })
    }

    const getViewResults = (params) =>{
        dispatch(AuctionActions.getBidListByCarId({car_id: params.car_id,auction_id:params.auction_id})).then(bidRes => {
            if (bidRes.status == 200) {   
                setCounter(0);
                bidRes.data['car_name'] = params.car_name;
                bidRes.data['reg_no'] = params.reg_no;
                bidRes.data['auction_name'] = params.auction_name;
                setBidcardata(bidRes.data);   
            }
          })
    }

    const hideModalBiddingPopup = () => {
        setBiddingPopup(false);
        document.body.classList.remove("overflow-hidden");
        
    }

    const handleRequestData = async (data) => {
        let page = data.page_number;
        data.tab = page;
        data.pagination = true;
        if (isMounted) {
            setFormAction('');
            setLoading(true);
            setPage(page);
            setPagination({});
            setTotalLengthWithoutPagination(0)
            setRunPaginationFunc(false);
            setTab(tab);
        }
        getFilterList(data);
    }

    const getFilterList = async (searchParams) => {   
        props.searchParams(searchParams);
    }

    const listingItems = (bidCarsList && bidCarsList.length) ? bidCarsList.map((biddenCarList, index) =>
        <tr key={index}> 
            <td>{biddenCarList.vid}</td>
            <td>
                <div className="auction-car-detail">
                    {(biddenCarList.car_details) ? biddenCarList.car_details.make+' '+biddenCarList.car_details.model+' '+biddenCarList.car_details.carversion : ''}

                    <span style={{display:'block'}}>{(biddenCarList.car_details) ? biddenCarList.car_details.reg_no : ''}</span>
                </div>
            </td>
            <td>
                <div className="saller-detail">
                    <span>{(biddenCarList.car_details) ? biddenCarList.car_details.seller_organization : ''}</span>
                    {/* <span>9718905471</span> */}
                </div>
            </td>
            <td>{biddenCarList.auction_name}</td>
            <td> 
             {biddenCarList.end_date_time ? DateFormat(biddenCarList.end_date_time, config.constants.dateFormatDDMMMYYYYHM) : ''}
            </td>
            <td>
                    {(biddenCarList.highest_bid) ? HELPER.addCurrencyFormatting(biddenCarList.highest_bid) : 0}<br />
                    {(biddenCarList.buyer_details) ? biddenCarList.buyer_details.organization : ''}
            </td>
            <td>{(biddenCarList.no_of_bids) ? biddenCarList.no_of_bids : 0}</td>
            <td>{(biddenCarList.bidder) ? biddenCarList.bidder : 0}
            </td>
            <td>
                <div className="red-price">
                    <ul>
                        <li>Reserved: {(biddenCarList.reserved_price) ? HELPER.addCurrencyFormatting(biddenCarList.reserved_price) : 0}</li>
                        <li>Expected: {(biddenCarList.expected_price) ? HELPER.addCurrencyFormatting(biddenCarList.expected_price) : 0}</li>
                        <li>Base: {(biddenCarList.base_price) ? HELPER.addCurrencyFormatting(biddenCarList.base_price) : 0}</li>

                    </ul>
                </div>
            </td>
            <td>
                {  (biddenCarList.end_date_time && (new Date(biddenCarList.end_date_time).getTime() - new Date().getTime()) > 0) ? 'Live':'Expired' }
            </td>
            <td>
                <div className="btn-view">
                    <button className="btn-line" onClick={()=>showModalBiddingPopup({car_id: biddenCarList.vid, car_name:(biddenCarList.car_details)?biddenCarList.car_details.make+''+biddenCarList.car_details.model+''+biddenCarList.car_details.carversion:'', reg_no:(biddenCarList.car_details)?biddenCarList.car_details.reg_no:'',auction_id:biddenCarList.auction_id,auction_name:biddenCarList.auction_name})}>View</button>
                </div>
            </td>
        </tr>
    ) : <tr><td colSpan={11} style={{textAlign:'center'}}>{(loading && props.filterTableData.loading) ? null :'No record found'}</td></tr>;

    return (

        <>
            <div className="auction-monitoring-data-table">
                <table>
                    <thead>
                        <tr> 
                            <th>VID</th>
                            <th>MMV Details</th>
                            <th>Seller Details</th>
                            <th>Auction Name</th>
                            <th>End Time</th>
                            <th>Highest Bid</th>
                            <th>No. of Bids</th>
                            <th>Bidders</th>
                            <th>REB Price</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listingItems}
                    </tbody>
                </table>
                <div className="pagination-outer">
                    <Pagination paginateData={{ page, runPaginationFunc, loading: loading, 'tableData': bidCarsList, totalLengthWithoutPagination, vehicleListPagination }} sendRequestDataToParent={handleRequestData} action={formAction} />
                </div>
            </div>
            <div className="bidding-detail-popup">
                <Modal show={biddingPopup} handleClose={hideModalBiddingPopup} >
                    <BiddingDetailPopup bidcardata={{bidcardata, loading }} canceledBid={canceledBidFromChild}/>
                </Modal>
            </div>
        </>
    );

}
const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? 'modal display-block' : 'modal display-none';

    return (
        <div className={showHideClassName}>
            <section className='modal-main'>
                {children}
                <button onClick={handleClose} className="close_icn">
                    <i className="ic-clearclose"></i>
                </button>

            </section>
        </div>
    );
};

export default AuctionMonitorFilterResult;