import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";

class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            groupListing: [{id: 1, name: 'Mahindra Thar'}, {id:2, name: 'Maruti Suzuki Breeza'}]
        }
    }


    render() { 
        // const { groupListing } = this.state;
        // const { auth } = this.props;
        return (
            ''
            // <div className="container" >
            //     <p style={{"textAlign":'center', "fontSize":20, "padding":"10px"}}>Select any group</p>
            //     <ul style={{"textAlign":'center', "fontSize":20, "padding":"10px"}}>
            //         {
            //             groupListing
            //             ?
            //                 groupListing.map(el=>{ 
            //                     return (<li key={el.id} style={{ "padding":"10px", 'textDecoration':'underline'}}>
            //                         <NavLink to={"/chat/" + (auth.authUser.name)+'/'+el.name} >{el.name}</NavLink>
            //                     </li>)
            //                 })
            //             :
            //             ''
            //         }
            //     </ul>
            // </div>
        );
    }
}


const mapStateToProps = state => {
    return {
       redirectTo: state.auth.redirectTo,
       auth: state.auth.authUser

    }
 }

const mapDispatchToProps = dispatch => {
    return {}
}


export default withTranslation('common') (withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard)));