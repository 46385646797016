import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { default as config } from '../../../config/config';
import { toast } from 'react-toastify';
import { VehicleActions } from '../../../store/action';

class ImageUploadForInventory extends Component {
    constructor(props) {
        super(props);
        this.handleAddImage = this.handleAddImage.bind(this);

        this.state = {
            loading: false,
            selectedFile: null,
            dragOver: false,
            errorNoficication: null,
            action: 'inventoryBulkUpload',
            email: '',
            inventoryData: props.inventoryData
        };
    }

    static getDerivedStateFromProps(nextProps) {
        let inventoryStateData = {};
        if (nextProps.inventoryData) {
            inventoryStateData['inventoryData'] = nextProps.inventoryData; 
        } 
        if(!nextProps.zipImageUpload){
            inventoryStateData['selectedFile'] =  null 
        }
        return inventoryStateData;
    }

    // hideModalZipUpload = () => {
    //     this.props.closeModalInventoryPopup();
    // }

    /**
       Handle Manually (File Input) Added Files
    **/
    handleAddImage = async event => {
        if (event.target.files) {
            const file = event.target.files[0];
            this.setState({ selectedFile: file })
        }
    }

    /**
    * On Submit filter
    */
    submitUpload = async (event) => {
        event.preventDefault();
        let loading = true;
        this.setState({ loading: true });

        let fileValid = true;
        let postData = {};
        //let fileName = (this.state.selectedFile) ? this.state.selectedFile.name : '';
        if (this.state.selectedFile) {
            // for (let fileData of this.state.selectedFile) {
            let fileName = this.state.selectedFile.name

            //IMAGE FILES ALLOWED
            if (fileName && !(/\.(zip)$/.test(fileName))) {
                loading = false;
                toast.error(this.props.t('pages.vehicle.bulk_upload_images.please_select_file_to_upload'));
                this.setState({ loading: false });
                // this.fileInput.value = "";
                document.getElementsByClassName('vehicleInventoryFile')[0].value = null

                fileValid = false
            }
            // }
        } else {
            fileValid = false
            loading = false;
            toast.error(this.props.t('pages.vehicle.bulk_upload_images.select_file'));
            this.setState({ loading: false });
        }
        if (fileValid) {
            const form = event.target;
            postData = new FormData(form);
            postData.append("dealer_id", this.state.inventoryData.dealer_id);
            postData.append("inventory_id", this.state.inventoryData.id);
            postData.append("make_id", this.state.inventoryData.make_id);
            postData.append("model_id", this.state.inventoryData.model_id);
            postData.append("version_id", this.state.inventoryData.version_id);
            postData.append('upload_type', config.constants.INVENTORY_IMAGE_FOLDER);
            postData.append('car_hash', this.state.inventoryData.car_id_hash)
            let headers = {
                'Content-Type': `multipart/form-data; boundary=${postData._boundary}`,
            }
            try{
                let searchResult = await this.props.actions.submitBulkUploadInventoryImages(postData, headers);
                this.setState({ loading: false }); 
                if (searchResult.status == 200) {  
                    this.removeSelectedFile();
                    toast.success('Inventory images are uploaded successfully');
                    this.props.closeModalInventoryPopup()
                } else {
                    toast.error(searchResult.error); 
                }
            }catch(e){
                this.setState({ loading: false }); 
            }
        }

    }


    /**
     * REMOVE SELECTED FILE HANDLER
     * @param {*} event 
     */
    removeSelectedFile = (event) => {

        this.setState({
            selectedFile: undefined
        });

        document.getElementsByClassName('vehicleInventoryFile')[0].value = null
    }


    render() {
        const { loading, selectedFile } = this.state;  

        // If file is set, change upload box text to file name
        let uploadText = this.state.selectedFile
            ? <div className="upload-file-name">

                <div className="upload-file-img">
                    <span className="suceess-icn">
                        <i className="ic-check"></i>
                    </span>
                    <h4>
                        {this.state.selectedFile.name}
                    </h4>
                    <i className="ic-clearclose" onClick={this.removeSelectedFile}></i>
                </div>

                <button
                    className="upload-button btn btn-primary"
                    type="button" onClick={(event) => this.openFileUpload(event)}
                >
                    Browse Another File
                </button>
            </div >
            : <div className="upload-txt else"  >
                <h4> Drop Files Here to Upload</h4>
                <span>Only ZIP file allowed</span>
                <div className="or-txt">
                    or
                </div>
                <button
                    className="btn-line file-btn" type="button" onClick={(event) => this.handleAddImage(event)}
                >
                    Browse File
                </button>

            </div>;

        return (

            <div style={{ textAlign: "center" }}>
                <form method="post" onSubmit={this.submitUpload} encType="multipart/form-data">

                    <div className="image-uploader-wrapper">
                        <div className={'display-box'}>
                            <div className="icon-text-box">
                                <div className="upload-icon">
                                    <i className="ic-file-icon" aria-hidden="true" />
                                </div>

                                <div className="upload-text">
                                    {uploadText}
                                </div>

                            </div>
                            <div>
                                <input
                                    type="file"
                                    ref="image"
                                    id="upload-image-input"
                                    className="upload-image-input vehicleInventoryFile"
                                    name="images"
                                    onChange={(event) => this.handleAddImage(event)}
                                    style={selectedFile ? { width: 0, height: 0 } : { width: '100%', height: '100%' }}
                                />
                            </div>
                        </div>
                    </div>


                    <div className="modal-footer footer-btn">
                        {
                            !loading
                                ?
                                <button className="btn-primary" >Upload</button>
                                :
                                <button className="btn-primary" type='button'>{this.props.t('common.please_wait')}</button>

                        }
                    </div>
                </form>
            </div>

        );
    }
}
const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            submitBulkUploadInventoryImages: bindActionCreators(
                VehicleActions.submitBulkUploadInventoryImages,
                dispatch
            )
        }
    }
}

export default withTranslation('common')(withRouter(connect(mapStateToProps, mapDispatchToProps)(ImageUploadForInventory)));



