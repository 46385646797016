import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InputField from '../../elements/InputField';
import { default as config } from '../../../config/config';
import { toast } from 'react-toastify';
import { VehicleActions } from '../../../store/action';

class FileUpload extends React.Component {
    constructor(props) {
        super(props);
        this.handleAddImage = this.handleAddImage.bind(this);

        this.state = {
            loading: false,
            selectedFile: null,
            dragOver: false,
            errorNoficication: null,
            action: 'inventoryBulkUpload',
            email: ''
        };
    }


    handleAddImage(e) {
        e.preventDefault();
        let file = e.target && e.target.files ? e.target.files[0] : null;

        if (file) {
            // Validate file is of type Image
            let fileType = file.type.split('/')[0];
            if (fileType !== "image") {
                console.log("Not an image file");
                // e.preventDefault();
                this.setState({
                    file: null,
                    errorNotification: "Not an image File",
                    dragOverClass: ""
                });
            }

            this.setState({
                selectedFile: file
            });
        }

    }
    /**
     * On Input Change Handler
     */
    onInputChange = async e => {
        await this.setState({ email: (e && e.target ? e.target.value : '') });
    }


    validateEmails = async (cemails) => {
        let re = /\S+@\S+\.\S+/;

        let emails = cemails.split(',');
        let validatedEmails = emails.filter(el => re.test(el))

        if (emails.length === validatedEmails.length) {
            return emails
        } else {
            return []
        }

    }

    /**
    * On Submit filter
    */
    submitUpload = async (event) => {
        event.preventDefault();
        
        let authUser = this.props.authUser || {}

        this.setState({ loading: true });
        let postData = {};
        let fileName = (this.state.selectedFile) ? this.state.selectedFile.name : '';
        const form = event.target;
        const data = new FormData(form);
        data.append("filename", fileName);
        data.append('upload_type', config.constants.INVENTORY_CSV_FOLDER)
        data.append("dealer_id", authUser.authUser ? authUser.authUser.user_id : 0);
        data.append("images", this.state.selectedFile);
        const formdata = data;
        if (this.state.email) {
            postData['emails'] = await this.validateEmails(this.state.email);
            if (!postData['emails'].length) {
                toast.error('Please enter valid emails');
                this.setState({ loading: false });
                return false;
            }
        }
        let headers = {
            'Content-Type': `multipart/form-data; boundary=${formdata._boundary}`,
        }
        if (this.state.selectedFile && fileName && (/\.(csv)$/.test(fileName))) {
            try{
                let searchResult = await this.props.actions.submitBulkUploadinventory(formdata, headers);  
                if (searchResult.status === 200) {
                    if (searchResult.data && searchResult.data.encodingError) {
                        toast.error(searchResult.message);
                    } else {
                        toast.success(searchResult.message);
                        this.props.hideInventoryUploadPopup()
                    }

                    this.setState({ loading: false, selectedFile: '', email: '' });
                    if (this.fileInput && this.fileInput.value) {
                        this.fileInput.value = "";
                    }
                }
            }catch(e){
                this.setState({ loading: false});
            }

        } else {
            toast.error(this.props.t('pages.vehicle.bulk_upload.please_select_file_to_upload'));
            this.setState({ loading: false });
            if (this.fileInput && this.fileInput.value) {
                this.fileInput.value = "";
            }
        }

    }

    /**
     * REMOVE SELECTED FILE HANDLER
     * @param {*} event 
     */
    removeSelectedFile = (event) => {

        this.setState({
            selectedFile: undefined
        });

        document.getElementsByClassName('vehicleInventoryCsv')[0].value = null
    }

    /**
     * OPEN FILE UPLOAD BOX
     */
    openFileUpload = () => {
        document.getElementsByClassName('vehicleInventoryCsv')[0].click()
    }

    render() {
        const { loading, email, selectedFile } = this.state;

        // Match drag over css to hover css
        let dragOverClass = this.state.dragOver
            ? `display-box drag-over`
            : `display-box`;

        // If file is set, change upload box text to file name
        let uploadText = this.state.selectedFile
            ? <div className="upload-file-name">

                <div className="upload-file-img">
                    <span className="suceess-icn">
                        <i className="ic-check"></i>
                    </span>
                    <h4>
                        {this.state.selectedFile.name}
                    </h4>
                    <i className="ic-clearclose" onClick={this.removeSelectedFile}></i>
                </div>
              
                <button
                    className="upload-button btn btn-primary"
                    type="button" onClick={(event) => this.openFileUpload(event)}
                >
                    Browse Another File
                </button>
            </div >
            : <div className="upload-txt else"  >
                <h4> Drop Files Here to Upload</h4>
                <span>Only .csv files are accepted and size should not be more then 1 GB</span>
                <div className="or-txt">
                    or
                </div>
                <button
                    className="btn-line file-btn" type="button" onClick={(event) => this.handleAddImage(event)}
                >
                    Browse File
                </button>

            </div>;


        return (
            <div style={{ textAlign: "center" }}>

                <form method="post" onSubmit={this.submitUpload} encType="multipart/form-data">

                    <div className="image-uploader-wrapper">
                        <div className={dragOverClass}>
                            <div className="icon-text-box">
                                <div className="upload-icon">
                                    <i className="ic-file-icon" aria-hidden="true" />
                                </div>

                                <div className="upload-text">
                                    {uploadText}
                                </div>
                            </div>
                            <div>
                                <input
                                    type="file"
                                    ref={ref => this.fileInput = ref} 
                                    id="upload-image-input"
                                    className="upload-image-input vehicleInventoryCsv"
                                    onChange={(event) => this.handleAddImage(event)}
                                    style={selectedFile ? { width: 0, height: 0 } : { width: '100%', height: '100%' }}
                                />
                            </div>
                        </div>
                    </div>

                    <fieldset className="m-xl-t upload-img-input">
                        <div className="material">
                            <InputField
                                inputProps={{
                                    id: 'email',
                                    type: "text",
                                    placeholder: " ",
                                    name: 'email',
                                    className: 'form-input',
                                    value: email
                                }}
                                onChange={this.onInputChange}
                            />
                            <label data-label={this.props.t('pages.vehicle.bulk_upload.email')} className="form-label"></label>

                        </div>
                    </fieldset>
                    <div className="modal-footer footer-btn m-t-0">
                        {
                            !loading
                                ?
                                <button className="btn-primary" >Upload</button>
                                :
                                <button className="btn-primary" type='button'>{this.props.t('common.please_wait')}</button>

                        }  
                    </div>
                </form>

            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
       authUser: state.auth.authUser

    }
}

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            submitBulkUploadinventory: bindActionCreators(
                VehicleActions.submitBulkUploadinventory,
                dispatch
            )
        }
    }
}

export default withTranslation('common')(withRouter(connect(mapStateToProps, mapDispatchToProps)(FileUpload)));



