import React, { Component } from 'react';
import Select from 'react-select';
import { StartDateCalendra, EndDateCalendra } from '../components/DatePicker'
import FloatingLabel from "floating-label-react";
import "floating-label-react/styles.css";
import AuctionBuyers from './AuctionBuyer'
import BasicDetail from './AuctionBasicDetail'





class AuctionAdd extends Component {
    constructor(props) {
        super(props);
        console.log(props)

        this.state = {

        };
    }



    render() {

        return (
            <div class="container-fluid-full">
                <div class="top-sec">
                    <div class="top-heading-action-sec">
                        <h1>Auctions</h1>
                    </div>
                </div>
                <div className="add-auctio-bg">
                    <BasicDetail />

                    <div className="row">
                        <AuctionBuyers />
                    </div>
                    <div className="btn-add-auction">
                        <button className="btn-primary">
                            Add Auction
                        </button>
                    </div>
                </div>

            </div>


        );
    }
}




export default AuctionAdd;