import React, { useState, useEffect } from 'react';
import AuctionMonitorFilter from './AuctionMonitorFilter';
import AuctionMonitorFilterResult from './AuctionMonitorFilterResult'
import { useSelector, useDispatch } from 'react-redux'
import { AuctionActions } from '../../../store/action';
import FullPageLoader from '../../common/FullPageLoader';
import Socket from '../../common/socket';

const AuctionMonitor = () => {
  const [bidcarData, setBidcarData] = useState({});
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({});
  const [runPaginationFunc, setRunPaginationFunc] = useState(true);
  const [totalLengthWithoutPagination, setTotalLengthWithoutPagination] = useState(0);
  const [page, setPage] = useState(1);
  const [tab, setTab] = useState(1);
  const [formAction, setFormAction] = useState('auctionMonitor');
  const [auctionCarBids, setAuctionCarBids] = useState({});
  const [filterData, setFilters] = useState({});
  const autionCars = useSelector(state => state.auctionCarsList);

  const dispatch = useDispatch();

  let getBiddenAuctionCars = async (params) => {
    setLoading(true);
    dispatch(AuctionActions.getAuctionBidCars(params)).then(bidRes => {
      if (bidRes.status == 200) {
        setBidcarData(bidRes.data);
        setPagination(bidRes.pagination);
        setLoading(false);
        setRunPaginationFunc(true);
        setTotalLengthWithoutPagination(bidRes.pagination && bidRes.pagination.total ? bidRes.pagination.total : 0)
      }
    })
  }

  useEffect(() => {
    // getBiddenAuctionCars({ page: page });
  }, []);

  const searchBidCars = async (params) => { 
    let newparams = { ...params }
    if(params.pagination){
      delete params.pagination;
      newparams = Object.assign(params,filterData)
    }  
    if(newparams.search || newparams.page_number>=1){
      newparams.page = (params.page_number) ? params.page_number : 1;
      if ('page_number' in newparams) {
        delete newparams['page_number'];
        delete newparams['tab'];
      }
      setFilters(newparams)
      getBiddenAuctionCars(newparams);
    }
    
  }

  /**
   * UPDATE LISTING
   */
  const updatedRealTimeBidsAuctionMonitor = (latestBid) => {

    let updatedList = bidcarData.filter(bidCars=> bidCars.vid !== latestBid.vid);
    updatedList.unshift(latestBid);

    if(updatedList.length>10) {
      updatedList.pop() 
    }

    setBidcarData(updatedList);


  }


  /**
   * UPDATE AUCTION CAR BIDS
   */
  const updatedRealTimeAuctionCarBids = (updatedCarBid) => {
    setAuctionCarBids(updatedCarBid)
  }

  
  return (
    <div className="container-fluid action-monitor-sec">
      {

          <Socket updatedRealTimeAuctionBidCars={updatedRealTimeBidsAuctionMonitor} updatedRealTimeAuctionCarBids={updatedRealTimeAuctionCarBids}/>

      }
      
      <FullPageLoader show={loading} />
      <AuctionMonitorFilter searchParams={searchBidCars} />
      <AuctionMonitorFilterResult filterTableData={{ bidCarlist: bidcarData, loading: loading, totalLengthWithoutPagination, page, runPaginationFunc }} pagination={pagination} action={formAction} tab={tab} searchParams={searchBidCars} realTimeAuctionCarBids={auctionCarBids} />
    </div>
  );
}

export default AuctionMonitor