import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Tab, Nav, Row } from 'react-bootstrap';
import Select from 'react-select';
import LiveAuctionTable from './LiveAuctionTable'

const SearchBy = [
    { value: 'Search By: Reg No.', label: 'Search By: Reg No.' },
    { value: 'Search By: Date', label: 'Search By: Date' },
    { value: 'Search By: Number', label: 'Search By: Number' },
];

class AuctionTab extends Component {
    constructor(props) {
        super(props);
        console.log(props)

        this.state = {
            selectedEngineNumber: null,
        };
    }

    handleChangesearchby = (selectedsearchby) => {
        this.setState({ selectedsearchby }
        );
    };

    render() {
        const { selectedsearchby } = this.state;
        return (

            <div >
                <Tab.Container id="" defaultActiveKey="first">
                    <div className="auction-tab">
                        <div className="tab-bg">
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="first">Live Auction <span>(8)</span></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second">Upcoming Auction <span>(4)</span></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="third">Expired <span>(8)</span></Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <div className="right-search-panel">

                                <fieldset class="form-select-bx">
                                    <div class="material">
                                        <Select
                                            value={selectedsearchby}
                                            onChange={this.handleChangesearchby}
                                            options={SearchBy}
                                            placeholder="Search By: Reg No."

                                        />
                                    </div>
                                </fieldset>
                                <fieldset class=" search-bx search-top-bar">
                                    <input id="" type="text" placeholder="Search " name="" class="form-input" />
                                    <i className="ic-search"></i>
                                </fieldset>
                            </div>
                        </div>

                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <div className="auction-data-table">
                                    <LiveAuctionTable />
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                Two
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                                Three
                            </Tab.Pane>

                        </Tab.Content>
                    </div>
                </Tab.Container>

            </div>




        );
    }
}


export default AuctionTab;