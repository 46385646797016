import React, { useState, useEffect } from "react";
import FullPageLoader from '../../common/FullPageLoader';
import { default as config } from '../../../config/config';
import DateFormat from 'dateformat';
import { CSVLink } from "react-csv"; 
import { useTranslation } from 'react-i18next';
import ConfirmationModal from '../../common/ConfirmationModal';
import { useSelector, useDispatch } from 'react-redux';
import * as HELPER from './../../../config/helper';

const BiddingDetailPopup = (props) => {
    const stateInfo = useSelector((state) => {
        return {
            allow_cancel_bid: state.auth.authUser.allow_cancel_bid || false
        }
    })  
    let bidOnCars = props.bidcardata;
    const { t, i18n } = useTranslation('common');
    const [loading, setLoading] = useState(bidOnCars.loading);
    const [isMounted, setIsMounted] = useState(false);
    const [bidCarsData, setbidCarsData] = useState({}); 
    const [colorId,setColor] = useState([]);
    const [cancelDetail,setCancel] = useState({
        all:false,
        data:[]
    }) 
    const [isDelete,setDelete] = useState(false);
    const tableHead = [
        t('bid_pop_up.bidder_detail'),
        // t('bid_pop_up.deposit_amount') ,
        // t('bid_pop_up.remaining_buyer_limit') ,
        t('bid_pop_up.bid_amount'),
        t('bid_pop_up.date_time'),
        t('bid_pop_up.bid_left'),
        t('bid_pop_up.bid_intermediary') 
    ]
    
    useEffect(() => {
        
        setIsMounted(true)
        setLoading(bidOnCars.loading);
        setColor([])
        setCancel({all: false, data: []})

        return () => {
            setIsMounted(false)
        }
    },[bidOnCars]);

    useEffect(() => { 
        setbidCarsData(bidOnCars.bidcardata);
        if (Object.keys(bidOnCars.bidcardata).length !== 0) {
            setLoading(false);
        } 
    }, [bidOnCars])

    const cancelBid = (params) => {  
        setCancel({
            ...cancelDetail,
            data:params
        }) 
        setDelete(true);
        // props.canceledBid(params);  
        // setColor(colorId.concat(params));
        // setCancel({
        //     ...cancelDetail,
        //     data:[]
        // })  
       
    }
    const handleInputChange = (event)=>{ 
        let selectedIds = []; 
        if(event.target.checked){
            bidCarsData.forEach((item)=>{
                if(item.status !=0){
                    selectedIds.push(item.id)
                } 
            }) 
        } 
        setCancel({
            all:event.target.checked,
            data:selectedIds 
        }) 
    } 
    const handleSingleCheckboxChange = async (event,list)=>{ 
        let changeIds = cancelDetail.data;
        if(event.target.checked){
            changeIds.push(list.id)
        }else{
            changeIds = await changeIds.filter(ids => ids != list.id)
        }   
        let flag = false;
        if (cancelDetail.all && bidCarsData.length !== cancelDetail.data.length) {
            flag = false; 
        } else if (!cancelDetail.all && bidCarsData.length === cancelDetail.data.length) {
            flag = true; 
        }   
        setCancel({
            all:flag,
            data:changeIds 
        })
    }  
    const templateCsv = [tableHead]
    if(bidCarsData && bidCarsData.length){ 
        bidCarsData.map((bidCars)=>{
            templateCsv.push([
                (bidCars.buyer_details) ? bidCars.buyer_details.organization : '', 
                `${config.constants.CURRENCY_SYMBOL+' '+bidCars.bid_amount}`,
                bidCars.created_at ? DateFormat(bidCars.created_at, config.constants.dateFormatDDMMMYYYYHM) : '',
                (bidCars.bid_count - bidCars.buyer_bid_count),
                (bidCars.buyer_details) ? bidCars.buyer_details.organization : ''  
            ])
        })
    }   
    const confirmationModalResponse = async (response)=>{   
        setDelete(false); 
        let auctionId = bidCarsData && bidCarsData[0] && bidCarsData[0]['auction_id'] || 0;
        let carId = bidCarsData && bidCarsData[0] && bidCarsData[0]['car_id'] || 0;
        if(response && response.response){ 
             props.canceledBid({bidsIds: cancelDetail.data, auction_id: auctionId, car_id: carId});  

             setLoading(true);

             setColor(colorId.concat(cancelDetail.data));
             setCancel({
                 ...cancelDetail,
                 data:[]
             })  
        }
         
    }
    
    const carBidList = (bidCarsData && bidCarsData.length) ? bidCarsData.map((bidCars, idx) =>
        <tr key={idx} style={((bidCars.status ==0) || colorId.includes(bidCars.id))? {background:'lightgray'}:{}}>
            <td>
            {
                ((bidCars.status ==0) || colorId.includes(bidCars.id) || !stateInfo.allow_cancel_bid) ? null :
                <li className="custom-control custom-checkbox">
                    <input id="all" type="checkbox" className="custom-control-input" onChange={(event) => handleSingleCheckboxChange(event,bidCars)} checked={cancelDetail.data.includes(bidCars.id) || cancelDetail.all}/>
                    <label htmlFor="all" className="custom-control-label"></label>
                </li> 
            } 
            </td>
            <td>
                <div className="bidder-detail">
                    <span>{(bidCars.buyer_details) ? bidCars.buyer_details.organization : ''}</span>
                    {/*<i className="ic-info-icon1" title={(bidCars.buyer_details) ? bidCars.buyer_details.organization : ''}></i>*/}
                </div>
                <div className="mob-id-txt"><span>Mo</span>{(bidCars.buyer_details) ? bidCars.buyer_details.dealership_contact : ''}</div>
                <div className="mob-id-txt"><span>ID</span> {bidCars.buyer_id} </div>
            </td>
            {/* <td>Rp 500.000</td>
            <td>--</td> */}
            <td>{HELPER.addCurrencyFormatting(bidCars.bid_amount)}</td>
            <td> {bidCars.created_at ? DateFormat(bidCars.created_at, config.constants.dateFormatDDMMMYYYYHM) : ''}</td>
            <td>{bidCars.bid_count - bidCars.buyer_bid_count}</td>
            <td>{(bidCars.buyer_details) ? bidCars.buyer_details.organization : ''}</td>
            {
                stateInfo.allow_cancel_bid ? <td>
                {
                    (bidCars.status ==0 || colorId.includes(bidCars.id)) ? null :
                    <i className="ic-clearclose cancel-icn" onClick={() => cancelBid([bidCars.id])}></i>
                }  
                </td>
                :
                null
            }
            
        </tr>
    ) : <tr key="1"><td colSpan={11} style={{textAlign:'center'}}>{!loading ? 'No record found':null} </td></tr>;
         
    return (
        <div>
            <FullPageLoader show={loading} />
            <div className="modal-header">
                <div className="heading-txt">
                    <h2>Bidder Details</h2>
                    <span>{ (bidCarsData && bidCarsData.length) ? bidCarsData[0].make_name+' '+bidCarsData[0].model_name+' '+bidCarsData[0].version_name+' :- '+bidCarsData[0].reg_no : null }</span><br></br>
                    <span>Auction Name - {bidCarsData.auction_name}</span>
                </div>
                <div className="bid-cancel-download-btn">
                    {
                        cancelDetail && cancelDetail.data.length
                        ?
                            <button className="btn-line" onClick={()=>cancelBid(cancelDetail.data)} disabled={(cancelDetail.data.length>0)?false:true}>
                                <i className="ic-clearclose"></i>
                                <span> Cancel Bid</span>
                            </button>
                        :
                            ''
                    }
                    
                    <button className="btn-line">
                        <i className="ic-pdf"></i>
                        {/* <span> Download List</span> */}
                        <CSVLink data={templateCsv} filename={`bid-listing-${Math.round(+new Date()/1000)}.csv`} className="download-csv"> <span> Download List</span></CSVLink>
                    </button>
                </div>
            </div>
            <div className="modal-body" >
                <div className="biding-table-data">
                    <table>
                        <thead>
                            <tr>
                            {
                                stateInfo.allow_cancel_bid ?
                                <th key="a">
                                    <li className="custom-control custom-checkbox">
                                        <input id="all" type="checkbox" className="custom-control-input"  onChange={handleInputChange} checked={cancelDetail.all}/>
                                        <label htmlFor="all" className="custom-control-label"></label>
                                    </li>
                                </th> : <th> </th> 
                            } 
                            {
                                tableHead.map((item,key)=><th key={key}>{item}</th>)
                            } 
                            {
                                (stateInfo.allow_cancel_bid) ? <th key="b">Action</th>:null
                            }
                                
                            </tr>
                        </thead>
                        <tbody>
                            {carBidList}
                        </tbody>
                    </table>

                </div>
            </div>
            {
            isDelete
                ?
                <ConfirmationModal confirmationData={{ show: isDelete, confirmationText: "Are you sure you want to delete selected bid?" }} confirmationModalResponse={confirmationModalResponse} />
                :
                null
            }
        </div>

    );

}


export default BiddingDetailPopup;