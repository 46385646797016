import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import DragSortableList from 'react-drag-sortable';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 15,
    marginRight: 15,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
    position: "relative"
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
    position: "relative"
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};


function Previews(props) {
    const [files, setFiles] = useState([]);
    const [firstLoad, setFirstLoad] = useState(true);
    const [sliderIndex, setSliderIndex] = useState({photoIndex: 0});
    const [imageLightbox, setImageLightbox] = useState({isOpen: false});
    
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: acceptedFiles => {
            let validreq = true;
            acceptedFiles.forEach((file) => {
                let image_size = parseInt(file.size, 10);
                if(!['image/jpg','image/jpeg','image/png'].includes(file.type)){
                    toast.error(props.t('pages.vehicle.add.select_valid_image_file'));
                    validreq = false;
                }
                else if (image_size < 25000 || image_size > 8000000) {
                    toast.error(props.t('pages.vehicle.add.image_size_valid'));
                    validreq = false;
                }
            })
            let uploadFiles = acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }));
            if (validreq) {
                setFiles(
                    files.concat(uploadFiles)
                );
            }
        }
    });

    const thumbs = files.map((file, index) => {
        return {
            content: <div onClick={e => e.stopPropagation()} style={thumb} key={index}>
                <div style={thumbInner}>
                    <img
                        src={file.preview}
                        style={img}
                    />
                </div>
                <span className="deleteImg" onClick={(e) => { deleteImage(e, index) }}><i className="ic-clearclose"></i></span>
                {/* <span className="rotateImg"><i className="sprite ic-rotate"></i></span> */}
            </div>
        }
    });

    if (!props.componentData.removeAllImageFlag && props.preUploadedImages && props.preUploadedImages.length && firstLoad) { 
        const preUploadedImages = props.preUploadedImages.map((file, index) => {
            return {
                preview: file.url,
                filename: file.image_name,
                index:index
            }
        });
        if (!files.length) {
            setFiles(
                preUploadedImages
            )
            setFirstLoad(false);

        }
    }
    else if (props.componentData && props.componentData.removeAllImageFlag ) {  //DELETE ALL IMAGES IF FLAG IS TRUE
        if (files.length) { 
            setFiles(
                []
            )
        }
    }

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks
        // files.forEach(file => URL.revokeObjectURL(file.preview)); 
        //CHECK IF NEW FILE EXISTS
        let newFiles = false  
        for(let file of files){
            if(file.path) newFiles = true
        }
        //IF ANY IMAGE REMOVED THEN UPDATE newFiles FLAG
        if(files.length !== props.preUploadedImages.length)  newFiles = true;

        props.handelFilesUpload(files, newFiles);
    }, [files]);

    const deleteImage = (e, eleIndex) => {
        e.stopPropagation();
        files.forEach((file, idx) => {
            if (eleIndex == idx) {
                URL.revokeObjectURL(file.preview);
            }
        });
        let filesArr = [...files];
        filesArr.splice(eleIndex, 1);
        setFiles(
            filesArr
        );
    }

    const onSort = (sortedList) => {
        let filesArr = [...files];
        let _sortedList = []
        for (let i = 0; i < sortedList.length; i++) {
            filesArr.forEach((item, index) => {
                if (index == sortedList[i].content.key) {
                    _sortedList.push(item);
                }
            });
        }
        setFiles(_sortedList);
    }

    var placeholder = (
        <div className="placeholderContent"></div>
    );

    let { isOpen } = imageLightbox;
    let {photoIndex} = sliderIndex;
    const images =  files && files.length ? files.map(img=> img.preview) : [];
  const set_image_index =(isOpen,Index)=>
  {   
    setSliderIndex({photoIndex:Index});
    setImageLightbox({ isOpen: isOpen });

  }
    return (

            <>
                {
                    files && files.length
                    ?
                        <div className="vechile-gallery">
                            <ul>
                                {
                                    files.map((file, key)=>
                                        <li className="vehicle-lightbox-slider" key={key} onClick={() => set_image_index(true,file.index)}>
                                            <img src={file.preview} name={file.filename} className="" />
                                            {(props.loginType =='seller')?null :
                                            <i className="ic-clearclose"  onClick={(e) => { deleteImage(e, key) }}></i>
                                            } 
                                        </li>
                                    )
                                }

                                <li className="add-more-vechile" style={(props.loginType=='seller')? {display:'none'}: null}>

                                    <div {...getRootProps({ className: 'dropzone' })}>
                                        <input type="file" accept="image/*" {...getInputProps()} className="upload-image-input" id="upload-image-input"/>
                                        {/* <h4>{props.t('pages.vehicle.add.drag_drop_here')}</h4>      */}
                                        <i className="ic-add"> </i>
                                        <span>Upload More Image</span>

                                    </div>
                                    <aside style={thumbsContainer}>
                                        <DragSortableList placeholder={placeholder}  moveTransitionDuration={0.3} onSort={onSort} type="grid" />
                                    </aside>

                                </li> 
                                
                            </ul>
                        </div>
                    :
                        <div style={{ textAlign: "center" }}>

                            <div className="image-uploader-wrapper">
                                <div className={'display-box'}>
                                    <div className="icon-text-box">
                                        <div className="upload-icon">
                                            <i className="ic-file-icon" aria-hidden="true" />
                                        </div>

                                        <div className="upload-text">

                                            <div className="upload-txt">
                                
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input type="file" accept="image/*" {...getInputProps()} className="upload-image-input" id="upload-image-input"/>
                                                    <h4>{props.t('pages.vehicle.add.drag_drop_here')}</h4>     
                                                    <span>{props.t('pages.vehicle.add.drop_image_files_title')}</span>   

                                                    <div className="or-txt">
                                                    or
                                                </div>
                                                <button className="btn-line file-btn" > Browse File </button>        
                                                </div>
                                                <aside style={thumbsContainer}>
                                                    <DragSortableList placeholder={placeholder} items={thumbs} moveTransitionDuration={0.3} onSort={onSort} type="grid" />
                                                </aside>


                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        
                        
                        </div>
                }



            {isOpen && (
                <Lightbox
                mainSrc={images[photoIndex]}
                nextSrc={images[(photoIndex + 1) % images.length]}
                prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                onCloseRequest={() => set_image_index(false,0)}
                onMovePrevRequest={() =>
                setSliderIndex({
                    photoIndex: (photoIndex + images.length - 1) % images.length,
                })
                }
                onMoveNextRequest={() =>
                setSliderIndex({
                    photoIndex: (photoIndex + 1) % images.length,
                })
                }
                />
            )}

            </>
            
        
    );
}

export default withTranslation('common')(Previews);
