import React, { Component } from 'react';
import InputField from '../elements/InputField';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { connect } from 'react-redux';
import { CommonMethodActions, UserActions, AuctionActions } from "../../store/action";
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { default as config } from '../../config/config';
import queryString from 'query-string';

class FilterBox extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            runPaginationFunc: false,
            totalLengthWithoutPagination: 0,
            cachedPostData: {},
            filterFields: [],
            iterate: 0,
            isResetClicked: false,
            mmvList: {}

        }


    }

    componentDidMount = async () => {
        this._isMounted = true; 
        let params = queryString.parse(this.props.location.search)
        
        // this.state.filterFields.forEach(async (el, key) => {
        for(let el of this.state.filterFields){
            
            //CREATE REFERENCES FOR LABEL ANIMATION
            if (el.createLabelRef) {
                this[el.name] = React.createRef()
            }

            if (el.name === 'car_make') {
                //IF NOT IN STATE THEN LOAD MMV LIST
                if (!Object.keys(this.props.mmvList).length) {
                    this.getMMVList();
                }
            }
            else if (el.name === 'created_by') {
                if (!Object.keys(this.props.createdByList).length) {
                    this.props.actions.getCreatedByList()
                }
            } else if (el.name === 'dealer_ids') {
                //GET SELLER LIST FROM STATE
                if (!this.props.sellerList || !Object.keys(this.props.sellerList).length) {
                    let filter = {};
                    filter['parent_dealer_type'] = [5];
                    this.props.actions.getSellersList({ filter: filter })
                }
            } else if (el.name === 'auction_city') {
                this.props.actions.getAuctionCityList();
            } else if (el.name === 'auction_qc_status' ) {
                if (this.props.auctionVehicleFilterList && !Object.keys(this.props.auctionVehicleFilterList).length) {
                    this.props.actions.getAuctionVehicleFilters()
                }
            } else if (el.name === 'auction_id_hash') {
                    await this.props.actions.getAllAuctionList({listForFilter: true}).then(auctions=>{
                        if(auctions.data){
                            el.option = auctions.data;
                            
                            let getAuctionIdHash = new URLSearchParams(window.location.search);
                            if(getAuctionIdHash.has('auction_id_hash')){ 
                                let selectedAuction = auctions.data.filter(auc=> auc.value === getAuctionIdHash.get('auction_id_hash')) ;
                                if(selectedAuction.length){
                                    el.value = selectedAuction[0] 
                                    this[el.name].current.classList.add('active-label'); 
                                }
                            } 
                            let selectedHash = el.option.filter(item=> item.value === params.auction_id_hash_in) ;
                            if(selectedHash.length){
                                el.value = selectedHash[0] 
                                this[el.name].current.classList.add('active-label');  
                            }  
                        }
                    })
            }else if (el.name === 'bank_auction') {
                await this.props.actions.getAllAuctionList({listForFilter: true ,auction_type:'live_expired'}).then(auctions=>{
                    if(auctions.data){
                        el.option = auctions.data; 
                    }
                })
            } else if (el.name === 'auction_status') {  
                let selectedStatus = el.option.filter(item=> item.value === params.auction_status) ;
                if(selectedStatus.length){
                    el.value = selectedStatus[0] 
                    setTimeout(()=>{
                        if(this[el.name].current) this[el.name].current.classList.add('active-label'); 
                    },200)  
                } 
            }  else if (el.name === 'post_auction_status') {    
                if(params.post_auction_status){ 
                    
                    setTimeout(()=>{
                        if(this[el.name].current) this[el.name].current.classList.add('active-label'); 
                    },100) 
                }  
            } else if (el.name === 'created_from' && params.from_date) {  
                el.value = new Date(params.from_date).toISOString();
                setTimeout(()=>{
                    if(this[el.name].current) this[el.name].current.classList.add('active-label'); 
                },100)   
            } else if (el.name === 'created_to' && params.to_date) {  
                el.value = new Date(params.to_date).toISOString();
                setTimeout(()=>{
                    if(this[el.name].current) this[el.name].current.classList.add('active-label'); 
                },100)   
            }   
        }

        this.setState({ filterFields: this.state.filterFields })

        this.filterSubmit()
    }
    

    /**
   * GET MMV LIST
   */
    getMMVList = async () => {
        this.props.actions.getMMVList()
            .then(resp => {
                if (Object.keys(resp).length && this._isMounted) this.setState({ mmvList: resp });
            })
    }

    /**
     * Update State
     */
    static getDerivedStateFromProps = (nextProps, prevState) => {
        let updatedStateData = {};
        prevState.iterate++;

        updatedStateData['filterFields'] = prevState.filterFields || [];

        if (nextProps.filterBox.runPaginationFunc) {
            updatedStateData['runPaginationFunc'] = false;
        }

        //UPDATE FILTER FILEDS
        if (prevState.iterate === 1 && nextProps.filterBox.filterFields && nextProps.filterBox.filterFields.length) {
            updatedStateData['filterFields'] = [...updatedStateData['filterFields'], ...nextProps.filterBox.filterFields];
            updatedStateData['showAdvanceSearchToggle'] = nextProps.filterBox.showAdvanceSearchToggle;
        }
        if (prevState.action !== nextProps.action) {
            updatedStateData['action'] = nextProps.action;
        }

        if (nextProps.filterBox.page) {
            updatedStateData['page'] = nextProps.filterBox.page;
        }

        updatedStateData['filterFields'].forEach((el, key) => {
            //     //UPDATE CITY DROPDOWN IF "dependent" KEY NOT FOUND, MEANS CITY LIST NOT DEPENDS ON CLUSTER

            if (el.name === 'car_make' && !el.option.length && !el.dependent) {
                updatedStateData['filterFields'][key]['option'] = nextProps.mmvList.make || [];
            }

            if (el.name === 'created_by' && !el.option.length && nextProps.createdByList) {
                updatedStateData['filterFields'][key]['option'] = nextProps.createdByList.map(list => { return { value: list.id, label: list.organization } });
            }

            if (el.name === 'dealer_ids' && !el.option.length && nextProps.sellerList && nextProps.sellerList.length) {
                updatedStateData['filterFields'][key]['option'] = nextProps.sellerList.map(list => { return { value: list.id, label: list.organization } });
            }

            if (el.name === 'bid_type') {
                updatedStateData['filterFields'][key]['option'] = [{ value: 1, label: "Without Bid" }, { value: 2, label: "With Bid" }];
            }
            if (el.name === 'status') {
                updatedStateData['filterFields'][key]['option'] = [{ value: 1, label: "Live" }, { value: 2, label: "Expired" }];
            } 
            if (el.name === 'auction_city' && nextProps.auctionCityList && nextProps.auctionCityList['data']) {
                updatedStateData['filterFields'][key]['option'] = nextProps.auctionCityList['data'].map(list => { return { value: list.id, label: list.name } });
            }

            if (el.name === 'auction_qc_status' && !el.option.length && nextProps.auctionVehicleFilterList && Object.keys(nextProps.auctionVehicleFilterList).length) {
                updatedStateData['filterFields'][key]['option'] = nextProps.auctionVehicleFilterList.QC_STATUS;
            }

            if (el.name === 'post_auction_status' && !el.option.length && nextProps.auctionVehicleFilterList && Object.keys(nextProps.auctionVehicleFilterList).length) {
                let params = queryString.parse(nextProps.location.search)
                let selectedStatus = nextProps.auctionVehicleFilterList.POST_AUCTION_STATUS.filter(item=> item.value === params.post_auction_status); 
                if(selectedStatus.length){
                    el.value = selectedStatus[0]   
                } 
                updatedStateData['filterFields'][key]['option'] = nextProps.auctionVehicleFilterList.POST_AUCTION_STATUS;
            }            
        });

        return updatedStateData;
    }

    componentWillUnmount = async () => {
        this._isMounted = false;
    }

    /**
     * Change Handler
     */
    handleChange = (name, index) => async e => {
        let { MODEL_VALUE_KEY, MAKE_ID_KEY } = config.constants;

        let val = '';
        if (name === 'created_from' || name === 'created_to' || name === 'registration_on') {
            val = (e) ? new Date(e).toISOString() : '' 
            if(name === 'created_from'){
                //SET MIN DATE FOR CREATED TO DATE
                for(let fields of this.state.filterFields){
                    if(fields.name === 'created_to'){
                        fields.minDate = new Date(val)
                    }
                }
            }

        } else if (e && typeof e.value !== 'undefined') {
            val = e
        } else if (name === 'premium_only') {
            val = !this.state.filterFields.premium_only
        } else if (name === 'car_make') {
            val = e
            let modelList = []

            for (let makeId of e) {
                let modelListArray = (this.props.mmvList['model'] && this.props.mmvList['model'].filter(model => model[MAKE_ID_KEY] === makeId.value)) || []
                modelList = [...modelList, ...modelListArray]
            }

            let updatedFields = this.state.filterFields
            for (let fields of updatedFields) {
                if (fields.name === 'car_model') {
                    fields.option = modelList.map((item)=>({...item,value:item['id']})) ;

                    //REMOVE MODEL IF MATCHED MAKE NOT FOUND
                    if(fields.value && fields.value.length){
                        let selectedMake = val.map(make=> make.value);

                        fields.value = fields.value.filter(model=> selectedMake.includes(model[MAKE_ID_KEY]) )
                    }
                    
                    //IF NO MODEL SELECTED RESET TITLE CLASS
                    if(!fields.value || (fields.value && !fields.value.length)){
                        this['car_model'].current.classList.remove('active-label');
                    }


                }
            }
            this.setState({ filterFields: updatedFields })

        } else {
            if (e && typeof e.value !== 'undefined') {
                val = e.value;
            } else if (e && e.target && typeof e.target.value !== 'undefined') {
                val = e.target.value;
            } else {
                val = e
            } 
        }

        let updateState = this.state.filterFields;
        
        let { isResetClicked } = this.state;
        updateState = updateState.map(el => {
            el.value = (el.name === name) ? val : el.value;
            return el;
        });

        if (this._isMounted) await this.setState({ filterFields: updateState, isResetClicked });


        //SELECT BOX DESIGN CLASS
        let getSelectRef = name;
        if (this[getSelectRef] && this[getSelectRef].current && !this[getSelectRef].current.classList.contains('active-label')) {
            this[getSelectRef].current.classList.add('active-label')
        } else {

            let getSelectedField = this.state.filterFields.filter(field => field.name === name);
            if (getSelectedField[0]) {

                if (getSelectedField[0]['type'] === 'select' && (!getSelectedField[0].value || (getSelectedField[0].value && !Object.keys(getSelectedField[0].value).length))) {
                    if(!getSelectedField[0].isMenuOpen) this[getSelectRef].current.classList.remove('active-label');
                } else if (getSelectedField[0]['type'] === 'multiselect' && (!getSelectedField[0].value || (getSelectedField[0].value && !(getSelectedField[0].value).length))) {
                    this[getSelectRef].current.classList.remove('active-label');
                } else if (getSelectedField[0]['type'] === 'date' && !getSelectedField[0].value) {
                    this[getSelectRef].current.classList.remove('active-label');
                } 
            }
        }

    }

    /**
    * 
    * ON SELECT CLICK HANDLER 
    */

    onSelectOpen  = (el) => {
        this[el['name']].current.classList.add('active-label')
        el.isMenuOpen = true;
    }

    /**
    * 
    * ON SELECT MENU CLOSE HANDLER 
    */
    onSelectClose = (el) => {
        if(!el.value){
            this[el['name']].current.classList.remove('active-label')

        }
        el.isMenuOpen = false;
    }


    /**
     * On multi select option remove
     */
    onRemove = type => async (selectedList, selectedItem) => {
        this.updateStateFun(type[0], selectedList);

    }


    /**
     * SUBMIT FILTER HANDLER
     */
    filterSubmit = async () => {
        let postData = { page_number: 1 };

        await this.setState({
            submitted: false,
            page: postData['page_number']
        });

        for (let field of this.state.filterFields) {
            if (field.value) {
                if(field.name === 'car_model'){
                    postData[field['name']] = field['value'].map(el => el.p_m_id)

                }else{
                    postData[field['name']] = Array.isArray(field['value']) ? field['value'].map(el => el.value) : ((typeof field['value'] === 'object') ? field['value'].value : field['value'])
                }
            }
        }

        //RESET FILTER DATA
        for (let field of Object.keys(postData)) {
            if ((postData[field] instanceof Array && !postData[field].length) || !postData[field]) delete postData[field]
        }

        postData['search'] = this.props.searchValue
        //first param is actual data, second param is to track from where we are calling
        let params = queryString.parse(this.props.location.search)
        if(params.type && params.type =='post_auction'){
            postData['searchType']  = 'post_auction'
        }

        //IF post_auction_status NOT EXISTS IN POSTDATA & EXISTS IN PARAMS
        if(!postData['post_auction_status'] && params['post_auction_status']) {
            postData['post_auction_status']  = params['post_auction_status']
        }

        this.props.sendRequestDataToParent(postData, 'filterBox');


    }


    /**
     * Reset Filter
     */
    filterReset = async () => {
        let updateState = this.state.filterFields;
        updateState = updateState.map(el => {

            if (this[el.name] && this[el.name].current)
                this[el.name].current.classList.remove('active-label')

            if (el.type === 'input') {
                el.value = '';
            } else if (el.type === 'multiselect') {
                el.value = [];
            } else {
                el.value = ''
            }
            return el;
        });
        if (this._isMounted) await this.setState({ filterFields: updateState, errors: {}, isResetClicked: true });
        this.props.sendRequestDataToParent({ 'action': 'resetAll', page_number: 1 });
    }

    /**
     * MULTISELECT DYNAMIC PLACEHOLDER
     */

    getDropdownButtonLabel = (placeholderButtonLabel, value) => {
        if (value.length === 0) {
            return placeholderButtonLabel;
        } else {
            return `${value.length} selected`;
        }
    }

    render() {
        const { filterFields, errors, isResetClicked } = this.state; 
        return (
            <div className="top-search-bar">
                <div className="top-ribben-search">
                    <div className="vechile-list-form">

                        {
                            filterFields
                                ?
                                (filterFields).map((el, key) => {
                                    if (el.type === 'input') {
                                        return (<fieldset className="search-bx" key={key}>
                                            <div className={"material "}>
                                                <InputField
                                                    inputProps={{
                                                        id: el.name,
                                                        type: "text",
                                                        placeholder: !el.createLabelRef ? " " : this.props.t(el.placeholder),
                                                        name: el.name,
                                                        value: el.value,
                                                        disabled: el.disabled || false,
                                                        className: "form-input"
                                                    }}
                                                    onChange={this.handleChange(el.name)}
                                                />
                                                
                                                <label data-label={this.props.t(el.placeholder)} className="form-label"></label>
                 
                                                {errors && errors[el.name] && <div className="error-block">{errors[el.name]}</div>}

                                            </div>
                                        </fieldset>)
                                    } else if (el.type === 'multiselect' && el.isVisible) {

                                        return (
                                            <fieldset className="seller-opt multiselect-dropDown" key={key}>
                                                <div className="material animation-effect" ref={this[el.name]}>
                                                    <ReactMultiSelectCheckboxes
                                                        isMulti
                                                        placeholderButtonLabel=""
                                                        onChange={this.handleChange(el.name)}
                                                        options={el.option || []}
                                                        value={el.value || null}
                                                        getOptionLabel={({ label }) => label}
                                                        getOptionValue={({ value }) => value}
                                                        getDropdownButtonLabel={() => this.getDropdownButtonLabel('', el.value)}


                                                    />
                                                    <label data-label={this.props.t(el.placeholder)} className="form-label"></label>
                                                </div>

                                            </fieldset>

                                        )

                                    } else if (el.type === 'date') { 
                                        return (
                                            <fieldset className="create-date" key={key}>
                                                <div className="material " ref={this[el.name]}>
                                                    <div className="datepicker">
                                                        <DatePicker
                                                            selected={el.value ? new Date(el.value) : null}
                                                            onChange={this.handleChange(el.name, key, el.type)}
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            placeholderText=" "
                                                            dateFormat={el.dateFormat || undefined }
                                                            minDate={el.minDate || undefined}
                                                            maxDate={el.maxDate || null}

                                                        />

                                                    </div>
                                                    <label data-label={this.props.t(el.placeholder)} className="form-label"></label>
                                                </div>
                                            </fieldset>
                                        )


                                    } else if (el.type === 'dateTime') {
                                        return (
                                            <fieldset className="create-date" key={key}>
                                                <div className="material " ref={this[el.name]}>
                                                    <DatePicker
                                                        className="form-control"
                                                        onChange={this.handleChange(el.name, key, el.type)}
                                                        placeholderText={''}
                                                        dateFormat={"dd-MM-yyyy HH:mm"}
                                                        selected={el.value ? new Date(el.value) : null}
                                                        startDate={el.startDate || null}
                                                        maxDate={el.maxDate || null}
                                                        minDate={!isResetClicked ? el.minDate : null}
                                                        minTime={!isResetClicked ? el.minTime : null}
                                                        maxTime={!isResetClicked ? el.maxTime : null}
                                                        isClearable
                                                        showTimeSelect
                                                        timeFormat="HH:mm"
                                                    />
                                                    <label data-label={this.props.t(el.placeholder)} className="form-label"></label>

                                                </div>
                                            </fieldset>
                                        )


                                    } else if (el.type === 'select') {
                                        return (
                                            <fieldset className={el.className + " seller-opt multiselect-dropDown"} key={key}>
                                                <div className="material animation-effect" ref={this[el.name]} onClick={()=>this.onSelectOpen(el)}>
                                                    <Select
                                                        id={el.name}
                                                        value={el.value || null}
                                                        onChange={this.handleChange(el.name, key)}
                                                        options={el.option}
                                                        isClearable={true}
                                                        groupBy={el.groupBy || false}
                                                        placeholder={''}
                                                        classNamePrefix='auction-dropdown'
                                                        onMenuClose={()=>this.onSelectClose(el)}

                                                    />
                                                    <label data-label={this.props.t(el.placeholder)} className="form-label"></label>

                                                </div>
                                            </fieldset>
                                        )
                                    } else {
                                        return ''
                                    }


                                })
                                :
                                ''
                        }

                        <div className="btn-submit-reset">
                            <button type="submit" onClick={this.filterSubmit} className="btn-primary">{this.props.t('common.search')}</button>

                            <button type="reset" onClick={this.filterReset} className="btn-reset">{this.props.t('common.reset')}</button>
                        </div>

                    </div>
                </div>

            </div>
        )
    }
}




const mapStateToProps = state => {
    return {
        inventoryFilters: state.common.inventoryFilterList || [],
        mmvList: state.common.mmvList || {},
        createdByList: state.auth.createdByList || [],
        sellerList: state.common.sellerList || {},
        auctionCityList: state.auction.auctionCityList || {},
        auctionVehicleFilterList: state.common.auctionVehicleFilterList || {}

    }

};

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            getMMVList: bindActionCreators(
                CommonMethodActions.getMMVList,
                dispatch
            ),
            getCreatedByList: bindActionCreators(
                UserActions.getCreatedByList,
                dispatch
            ),
            getSellersList: bindActionCreators(
                CommonMethodActions.getSellersList,
                dispatch
            ),
            getAuctionCityList: bindActionCreators(
                AuctionActions.auctionCityList,
                dispatch
            ),
            getAuctionVehicleFilters: bindActionCreators(
                CommonMethodActions.getAuctionVehicleFilters,
                dispatch
            ),
            getAllAuctionList: bindActionCreators(
                AuctionActions.getAuctionList,
                dispatch
            )
        }
    }
}

export default withTranslation('common')(withRouter(connect(mapStateToProps, mapDispatchToProps)(FilterBox)));