import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InputField from '../../elements/InputField';
import { default as config } from '../../../config/config';
import { toast } from 'react-toastify';
import { VehicleActions } from '../../../store/action';
const IMAGECOUNT = 20;

class InventoryBulkImageUpload extends React.Component {
    constructor(props) {
        super(props);
        this.handleAddImage = this.handleAddImage.bind(this);
        this.state = {
            loading: false,
            selectedFile: null,
            dragOver: false,
            errorNoficication: null,
            action: 'inventoryBulkUpload',
            email: ''
        };
    }


    /**
     * Handle Manually (File Input) Added Files
     */
    handleAddImage = async event => {
        event.preventDefault();

        if (event.target.files) {
            const file = event.target.files[0];
            this.setState({ selectedFile: file })
        }
    }

    /**
     * On Input Change Handler
     */
    onInputChange = async e => {
        await this.setState({ email: (e && e.target ? e.target.value : '') });
    }


    validateEmails = async (cemails) => {
        let re = /\S+@\S+\.\S+/;

        let emails = cemails.split(',');
        let validatedEmails = emails.filter(el => re.test(el))

        if (emails.length === validatedEmails.length) {
            return emails
        } else {
            return []
        }

    }

    /**
    * On Submit filter
    */
    submitUpload = async (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        let fileValid = true;
        let postData = {}; 

        if (this.state.selectedFile) { 
            let fileName = this.state.selectedFile.name 
            //IMAGE FILES ALLOWED
            if (fileName && !(/\.(zip)$/.test(fileName))) { 
                toast.error(this.props.t('pages.vehicle.bulk_upload_images.please_select_file_to_upload')); 
                // this.fileInput.value = "";
                document.getElementsByClassName('vehicleImagesFileBox')[0].value = null 
                fileValid = false
            } 
        } else {
            fileValid = false
            toast.error(this.props.t('pages.vehicle.bulk_upload_images.select_file'));
        }
        if (fileValid) { 
            const form = event.target;
            postData = new FormData(form);
             
            postData.append("dealer_id", 1);

            postData.append('upload_type', config.constants.INVENTORY_IMAGE_FOLDER);
            let headers = {
                'Content-Type': `multipart/form-data; boundary=${postData._boundary}`,
            }

            try{
                let searchResult = await this.props.actions.submitBulkUploadVehicleImages(postData, headers);
                if (searchResult.status == 200) { 
                    this.setState({loading: false, email: '' }); 
                    this.removeSelectedFile();
                    toast.success(searchResult.message);
                    this.props.hideInventoryUploadPopup()
                } else {
                    toast.error(searchResult.error);
                    this.setState({ loading: false }); 
                }
            }catch(e){
                this.setState({ loading: false }); 
            }
        }else{ 
            this.setState({ loading: false });
        }

    }

    /**
     * REMOVE SELECTED FILE HANDLER
     * @param {*} event 
     */
    removeSelectedFile = (event) => {
        this.setState({
            selectedFile: undefined
        });

        document.getElementsByClassName('vehicleImagesFileBox')[0].value = null
    }

    /**
     * OPEN FILE UPLOAD BOX
     */
    openFileUpload = () => {
        document.getElementsByClassName('vehicleImagesFileBox')[0].click()
    }

    render() {
        const { loading, email, selectedFile } = this.state;

        // Match drag over css to hover css
        let dragOverClass = this.state.dragOver
            ? `display-box drag-over`
            : `display-box`;

        // If file is set, change upload box text to file name
        let uploadText = this.state.selectedFile
            ? <div className="upload-file-name">
                <div className="upload-file-img">
                    <span className="suceess-icn">
                        <i className="ic-check"></i>
                    </span>

                    <h4>{this.state.selectedFile.name}

                    </h4>
                    <i className="ic-clearclose" onClick={this.removeSelectedFile}></i>
                </div>
                <button
                    className="upload-button btn btn-primary"
                    type="button" onClick={(event) => this.openFileUpload(event)}
                >
                    Browse Another File
                </button>
            </div >
            : <div className="upload-txt else"  >
                <h4> Drop Files Here to Upload</h4>
                <span>Only .zip file are accepted and size should not be more then 1 GB</span>
                <div className="or-txt">
                    or
                </div>
                <button
                    className="btn-line file-btn" type="button" onClick={(event) => this.handleAddImage(event)}
                >
                    Browse File
                </button>

            </div>;

        // Show Error message if file type is not an image
        let errorNotification = this.state.errorNotification
            ? <div className="error-notification">
                <p>{this.state.errorNotification}</p>
            </div>
            : null;


        return (
            <div style={{ textAlign: "center" }}>

                <form method="post" onSubmit={this.submitUpload} encType="multipart/form-data">
                    <div className="image-uploader-wrapper">
                        <div className={dragOverClass}>


                            <div className="icon-text-box">
                                <div className="upload-icon">
                                    <i className="ic-zipfile" aria-hidden="true" />
                                </div>

                                <div className="upload-text">
                                    {uploadText}
                                </div>

                                {/* {errorNotification} */}
                            </div>

                            <div>
                                <input
                                    type="file"
                                    ref="image"
                                    id="upload-image-input"
                                    className="upload-image-input vehicleImagesFileBox"
                                    name="images"
                                    onChange={(event) => this.handleAddImage(event)}
                                    style={selectedFile ? { width: 0, height: 0 } : { width: '100%', height: '100%' }}
                                />


                            </div>


                        </div>
                    </div>
                    <fieldset className="m-xl-t upload-img-input">
                        <div className="material">
                            <InputField
                                inputProps={{
                                    id: 'email',
                                    type: "text",
                                    placeholder: " ",
                                    name: 'email',
                                    className: 'form-input',
                                    value: email
                                }}
                                onChange={this.onInputChange}
                            />
                            <label data-label={this.props.t('pages.vehicle.bulk_upload.email')} className="form-label"></label>

                        </div>
                    </fieldset>
                    <div className="modal-footer footer-btn m-t-0">
                    {
                            !loading
                                ?
                                <button className="btn-primary" >Upload</button>
                                :
                                <button className="btn-primary" type='button'>{this.props.t('common.please_wait')}</button>

                        } 
                    </div>
                </form>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            submitBulkUploadVehicleImages: bindActionCreators(
                VehicleActions.submitBulkUploadVehicleImages,
                dispatch
            )
        }
    }
}

export default withTranslation('common')(withRouter(connect(mapStateToProps, mapDispatchToProps)(InventoryBulkImageUpload)));



