import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Calendra() {
    // const [value, onChange] = useState(new Date());
    const [startDate, setStartDate] = useState(null);

    return (
        <div className="datepicker">
            <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="Created On"
            />
        </div>
    );
}

function DateTimePicker() {
    // const [value, onChange] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());

    return (
        <div className="datepicker">
            <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                timeInputLabel="Time:"
                timeInputLabel="Time:"
                dateFormat="MM/dd/yyyy h:mm aa"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"

            />
            <label data-label="Start Date & Time" class="form-label"></label>
        </div>
    );
}
function EndDateTimePicker() {
    // const [value, onChange] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());

    return (
        <div className="datepicker">
            <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                timeInputLabel="Time:"
                timeInputLabel="Time:"
                dateFormat="MM/dd/yyyy h:mm aa"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"

            />
            <label data-label="End Date & Time" class="form-label"></label>
        </div>
    );
}

function Calendra2() {
    // const [value, onChange] = useState(new Date());
    const [startDate, setStartDate] = useState(null);

    return (
        <div className="datepicker">
            <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="Registration On"
            />
        </div>
    );
}

function StartDateCalendra() {
    // const [value, onChange] = useState(new Date());
    const [startDate, setStartDate] = useState(null);

    return (
        <div className="datepicker">
            <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText=""


            />
            <label data-label="Start Date" class="form-label"></label>
        </div>
    );
}

function EndDateCalendra() {
    // const [value, onChange] = useState(new Date());
    const [startDate, setStartDate] = useState(null);

    return (
        <div className="datepicker">
            <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText=""


            />
            <label data-label="End Date" class="form-label"></label>
        </div>
    );
}


function CreatedFrom() {
    // const [value, onChange] = useState(new Date());
    const [startDate, setStartDate] = useState(null);

    return (
        <div className="datepicker">
            <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="Created From"


            />
            {/* <label data-label="Start Date" class="form-label"></label> */}
        </div>
    );
}

function CreatedTo() {
    // const [value, onChange] = useState(new Date());
    const [startDate, setStartDate] = useState(null);

    return (
        <div className="datepicker">
            <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="Created To"


            />
            {/* <label data-label="Start Date" class="form-label"></label> */}
        </div>
    );
}

function CalendraSimple() {
    // const [startDate, setStartDate] = useState(new Date());

    // return (
    //     <div className="datepicker">
    //         <DatePicker
    //             selected={startDate}
    //             onChange={(date) => setStartDate(date)}
    //             peekNextMonth
    //             showMonthDropdown
    //             showYearDropdown
    //             dropdownMode="select"
    //         />
    //     </div>
    const [startDate, setStartDate] = useState(new Date());
    return (
        <div className="datepicker">
            <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                showFullMonthYearPicker
                dropdownMode="select"
            />
        </div>
    );
}
export { Calendra, Calendra2, CalendraSimple, StartDateCalendra, EndDateCalendra, DateTimePicker, EndDateTimePicker, CreatedTo, CreatedFrom }